import styled from 'styled-components'

export const TypographyWrapper = styled.span`
  margin: 0;
  font-family: var(--dls-brand-font-family);
  line-height: var(--dls-line-height);

  &.x-small {
    font-size: var(--dls-font-size-x-small);
    font-weight: var(--dls-font-weight-x-small);
    letter-spacing: var(--dls-letter-spacing-x-small);
  }

  &.tag-regular {
    font-size: var(--dls-font-size-tag-regular);
    font-weight: var(--dls-font-weight-tag-regular);
    line-height: var(--dls-line-height-135);
    letter-spacing: var(--dls-letter-spacing-tag-regular);
  }

  &.tag-bold {
    font-size: var(--dls-font-size-tag-bold);
    font-weight: var(--dls-font-weight-tag-bold);
    line-height: var(--dls-line-height-135);
    letter-spacing: var(--dls-letter-spacing-tag-bold);
  }

  &.cta-label-sm {
    line-height: var(--dls-line-height-125);
    font-size: var(--dls-font-size-cta-label-sm);
    font-weight: var(--dls-font-weight-cta-label-sm);
    letter-spacing: var(--dls-letter-spacing-cta-label-sm);
  }

  &.cta-label-lg {
    line-height: var(--dls-line-height-125);
    font-size: var(--dls-font-size-cta-label-lg);
    font-weight: var(--dls-font-weight-cta-label-lg);
    letter-spacing: var(--dls-letter-spacing-cta-label-lg);
  }

  &.body-base-regular {
    line-height: var(--dls-line-height-135);
    font-size: var(--dls-font-size-body-base-regular);
    font-weight: var(--dls-font-weight-body-base-regular);
  }

  &.body-base-light {
    line-height: var(--dls-line-height-135);
    font-size: var(--dls-font-size-body-base-light);
    font-weight: var(--dls-font-weight-body-base-light);
  }

  &.body-base-bold {
    line-height: var(--dls-line-height-135);
    font-size: var(--dls-font-size-body-base-bold);
    font-weight: var(--dls-font-weight-body-base-bold);
  }

  &.subheading-regular {
    font-size: var(--dls-font-size-subheading-regular);
    font-weight: var(--dls-font-weight-subheading-regular);
    letter-spacing: var(--dls-letter-spacing-subheading-regular);
  }

  &.subheading-light {
    font-size: var(--dls-font-size-subheading-light);
    font-weight: var(--dls-font-weight-subheading-light);
    letter-spacing: var(--dls-letter-spacing-subheading-light);
  }

  &.subheading-bold {
    font-size: var(--dls-font-size-subheading-bold);
    font-weight: var(--dls-font-weight-subheading-bold);
    letter-spacing: var(--dls-letter-spacing-subheading-bold);
  }

  &.heading-sm-regular {
    font-size: var(--dls-font-size-heading-sm-regular);
    font-weight: var(--dls-font-weight-heading-sm-regular);
    letter-spacing: var(--dls-letter-spacing-heading-sm-regular);
  }

  &.heading-sm-bold {
    font-size: var(--dls-font-size-heading-sm-bold);
    font-weight: var(--dls-font-weight-heading-sm-bold);
    letter-spacing: var(--dls-letter-spacing-heading-sm-bold);
  }

  &.heading-md-regular {
    font-size: var(--dls-font-size-heading-md-regular);
    font-weight: var(--dls-font-weight-heading-md-regular);
    letter-spacing: var(--dls-letter-spacing-heading-md-regular);
  }

  &.heading-md-bold {
    font-size: var(--dls-font-size-heading-md-bold);
    font-weight: var(--dls-font-weight-heading-md-bold);
    letter-spacing: var(--dls-letter-spacing-heading-md-bold);
  }

  &.heading-lg-regular {
    font-size: var(--dls-font-size-heading-lg-regular);
    font-weight: var(--dls-font-weight-heading-lg-regular);
    letter-spacing: var(--dls-letter-spacing-heading-lg-regular);
  }

  &.heading-lg-bold {
    font-size: var(--dls-font-size-heading-lg-bold);
    font-weight: var(--dls-font-weight-heading-lg-bold);
    letter-spacing: var(--dls-letter-spacing-heading-lg-bold);
  }

  &.heading-xl-regular {
    font-size: var(--dls-font-size-heading-xl-regular);
    font-weight: var(--dls-font-weight-heading-xl-regular);
    letter-spacing: var(--dls-letter-spacing-heading-xl-regular);
  }

  &.heading-xl-bold {
    font-size: var(--dls-font-size-heading-xl-bold);
    font-weight: var(--dls-font-weight-heading-xl-bold);
    letter-spacing: var(--dls-letter-spacing-heading-xl-bold);
  }

  &.heading-2xl-regular {
    font-size: var(--dls-font-size-heading-2xl-regular);
    font-weight: var(--dls-font-weight-heading-2xl-regular);
  }

  &.heading-2xl-bold {
    font-size: var(--dls-font-size-heading-2xl-bold);
    font-weight: var(--dls-font-weight-heading-2xl-bold);
  }

  &.heading-3xl-regular {
    font-size: var(--dls-font-size-heading-3xl-regular);
    font-weight: var(--dls-font-weight-heading-3xl-regular);
  }

  &.heading-3xl-bold {
    font-size: var(--dls-font-size-heading-3xl-bold);
    font-weight: var(--dls-font-weight-heading-3xl-bold);
  }

  @media (min-width: 768px) {
    &.x-small {
      font-size: var(--dls-desktop-font-size-x-small);
    }

    &.tag-regular {
      font-size: var(--dls-desktop-font-size-tag-regular);
      letter-spacing: var(--dls-desktop-letter-spacing-tag-regular);
    }

    &.tag-bold {
      font-size: var(--dls-desktop-font-size-tag-bold);
      letter-spacing: var(--dls-desktop-letter-spacing-tag-bold);
    }

    &.cta-label-sm {
      font-size: var(--dls-desktop-font-size-cta-label-sm);
      letter-spacing: var(--dls-desktop-letter-spacing-cta-label-sm);
    }

    &.cta-label-lg {
      font-size: var(--dls-desktop-font-size-cta-label-lg);
      letter-spacing: var(--dls-desktop-letter-spacing-cta-label-lg);
    }

    &.body-base-regular {
      font-size: var(--dls-desktop-font-size-body-base-regular);
      letter-spacing: var(--dls-desktop-letter-spacing-body-base-regular);
    }

    &.body-base-light {
      font-size: var(--dls-desktop-font-size-body-base-light);
      letter-spacing: var(--dls-desktop-letter-spacing-body-base-light);
    }

    &.body-base-bold {
      font-size: var(--dls-desktop-font-size-body-base-bold);
      letter-spacing: var(--dls-desktop-letter-spacing-body-base-bold);
    }

    &.subheading-regular {
      font-size: var(--dls-desktop-font-size-subheading-regular);
      letter-spacing: var(--dls-desktop-letter-spacing-subheading-regular);
    }

    &.subheading-light {
      font-size: var(--dls-desktop-font-size-subheading-light);
      letter-spacing: var(--dls-desktop-letter-spacing-subheading-light);
    }

    &.subheading-bold {
      font-size: var(--dls-desktop-font-size-subheading-bold);
      letter-spacing: var(--dls-desktop-letter-spacing-subheading-bold);
    }

    &.heading-sm-regular {
      font-size: var(--dls-desktop-font-size-heading-sm-regular);
      letter-spacing: var(--dls-desktop-letter-spacing-heading-sm-regular);
    }

    &.heading-sm-bold {
      font-size: var(--dls-desktop-font-size-heading-sm-bold);
      letter-spacing: var(--dls-desktop-letter-spacing-heading-sm-bold);
    }

    &.heading-md-regular {
      font-size: var(--dls-desktop-font-size-heading-md-regular);
      letter-spacing: var(--dls-desktop-letter-spacing-heading-md-regular);
    }

    &.heading-md-bold {
      font-size: var(--dls-desktop-font-size-heading-heading-md-bold);
      letter-spacing: var(--dls-desktop-letter-spacing-heading-md-bold);
    }

    &.heading-lg-regular {
      font-size: var(--dls-desktop-font-size-heading-lg-regular);
      letter-spacing: var(--dls-desktop-letter-spacing-heading-lg-regular);
    }

    &.heading-lg-bold {
      font-size: var(--dls-desktop-font-size-heading-lg-bold);
      letter-spacing: var(--dls-desktop-letter-spacing-heading-lg-bold);
    }

    &.heading-xl-regular {
      font-size: var(--dls-desktop-font-size-heading-xl-regular);
      letter-spacing: var(--dls-desktop-letter-spacing-heading-xl-regular);
    }

    &.heading-xl-bold {
      font-size: var(--dls-desktop-font-size-heading-xl-bold);
      letter-spacing: var(--dls-desktop-letter-spacing-heading-xl-bold);
    }

    &.heading-2xl-regular {
      font-size: var(--dls-desktop-font-size-heading-2xl-regular);
    }

    &.heading-2xl-bold {
      font-size: var(--dls-desktop-font-size-heading-2xl-bold);
    }

    &.heading-3xl-regular {
      font-size: var(--dls-desktop-font-size-heading-3xl-regular);
    }

    &.heading-3xl-bold {
      font-size: var(--dls-desktop-font-size-heading-3xl-bold);
    }
  }
`
