import {useCallback, useEffect, useRef} from 'react'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'

import {useGenericCta} from '../useGenericCta'
import {IUseSuccessScreenProps} from './directAssessment.interface'
import {useConsultTracking} from '../tracking/useConsultTracking'
import {checkIsApp} from 'src/utils/checkIsApp'
import {useRedirection} from '..'
import {useCancelAppointment} from '../consult/useCancelAppointment/useCancelAppointment'
import {useFutureAppointments} from '../consult/useFutureAppointments/useFutureAppointments'
import {useRescheduleAppointment} from '../consult/useRescheduleAppointment/useRescheduleAppointment'
import {useGetAppointmentDetails} from '../consult/useGetAppointmentDetails/useGetAppointmentDetails'
import {CLIENT_ENV} from 'src/constants/clientEnv'
import {useUserContent} from '../useUserContent/useUserContent'
import {useCommsActiveRoute} from 'src/stores/useCommsActiveRoute/useCommsActiveRoute.store'

const BRAND = CLIENT_ENV.NEXT_PUBLIC_BRAND || 'mm'

function useDaSuccessScreen({
  successScreen,
  supportedCategories,
}: IUseSuccessScreenProps) {
  const [
    {isRescheduleInProgress, rescheduleAppointmentData},
    {rescheduleAppointment, resetRescheduleAppointmentData},
  ] = useRescheduleAppointment()

  const [
    {futureAppointmentData, loadingFutureAppointments},
    {checkFutureAppointments},
  ] = useFutureAppointments()

  const [{isCancelled}, {cancelAppointment}] = useCancelAppointment()

  const {data} = useCommsActiveRoute()
  const [{currentConsultVersion = ''}, {handleRedirection = noop}] =
    useRedirection()

  const [
    {
      appointmentDetails: appointmentDetailsFromScheduleToken,
      isAppointmentDataLoading,
    },
    {getAppointmentDetails},
  ] = useGetAppointmentDetails()

  const [{}, {getUserContent: getFloatingIslandNudgeData}] = useUserContent()

  const isApp = checkIsApp()

  const {handleCta} = useGenericCta()
  const {pushFutureAppointmentDataToDataLayer} = useConsultTracking({})

  const {pendingOrderAppointment} = futureAppointmentData || {}
  const {
    scheduleToken,
    rxCategories = [],
    imageUploaded = 0,
  } = pendingOrderAppointment || {}

  const onDownloadCtaClick = useCallback(
    (link) => {
      if (link) {
        handleCta({action: 'LINK', link})
      }
    },
    [handleCta]
  )

  const handleCommsRedirect = useCallback(() => {
    if (isApp) {
      handleRedirection({
        action: 'SPA_LINK',
        link: data?.link,
        version: currentConsultVersion,
      })
    }
  }, [currentConsultVersion, data?.link, handleRedirection, isApp])

  const onReschedulePendingOrderAppointment = useCallback(() => {
    //TODO: refactor and use switch case here in
    if (!imageUploaded && supportedCategories.includes(rxCategories[0])) {
      handleCta({
        action: 'SPA_LINK',
        link: `upload-image?schedule_token=${scheduleToken}`,
      })
    } else if (imageUploaded && supportedCategories.includes(rxCategories[0])) {
      handleCta({
        action: 'SPA_LINK',
        link: `confirmation-screen?schedule_token=${scheduleToken}`,
      })
    } else {
      handleCta({
        action: 'SPA_LINK',
        link: `confirmation-screen?schedule_token=${scheduleToken}`,
      })
    }
  }, [
    imageUploaded,
    supportedCategories,
    rxCategories,
    handleCta,
    scheduleToken,
  ])

  const futureAppointmentLoaded = useRef(false)

  useEffect(() => {
    if (loadingFutureAppointments) {
      futureAppointmentLoaded.current = true
      return
    }

    if (futureAppointmentLoaded.current && !isEmpty(futureAppointmentData)) {
      pushFutureAppointmentDataToDataLayer(futureAppointmentData)
    }
  }, [
    loadingFutureAppointments,
    futureAppointmentData,
    pushFutureAppointmentDataToDataLayer,
  ])

  return [
    {
      category: futureAppointmentData?.category,
      brand: BRAND,
      isReschedule: true,
      showInfoCards: false,
      isRescheduleInProgress,
      rescheduleAppointmentData,
      futureAppointmentData,
      loadingFutureAppointments,
      isCancelled,
      successScreen,
      showAppointmentCardButtons: false,
      appointmentDetailsFromScheduleToken,
      isAppointmentDataLoading,
    },
    {
      rescheduleAppointment,
      checkFutureAppointments,
      cancelAppointment,
      handleCta,
      resetRescheduleAppointmentData,
      onDownloadCtaClick,
      getAppointmentDetails,
      handleCommsRedirect,
      onReschedulePendingOrderAppointment,
      getFloatingIslandNudgeData,
    },
  ] as const
}

export {useDaSuccessScreen}
