import React from 'react'

import {SupportTicketListingItemProps} from './SupportTicketListingItem.types'
import {StyledSupportTicketListingItem} from './SupportTicketListingItem.styles'
import {
  ChevronUp,
  OptimizedImage,
  RatingStarSelector,
  Typography,
  TypographyVariants,
} from '@web-components'
import {HelpAndSupportTicketStatus} from '@mosaic-wellness/fe-types'

const SupportTicketListingItem = (props: SupportTicketListingItemProps) => {
  const {
    status = '',
    statusTag = 'Waiting for your reply',
    createdOn = {label: '', value: ''},
    title = 'My order is not delivered',
    icon = '',
    ratingData = {
      rating: '0',
      label: 'Rate your experience',
      totalRating: 5,
      ctaActions: [],
    },
    handleTicketPress,
    handleRatingSelection,
    actions = [],
  } = props || {}

  return (
    <StyledSupportTicketListingItem
      isOngoing={[
        HelpAndSupportTicketStatus.INITIATED,
        HelpAndSupportTicketStatus.IN_PROGRESS,
        HelpAndSupportTicketStatus.QUEUED,
      ].includes(status as HelpAndSupportTicketStatus)}
      isBenched={status === HelpAndSupportTicketStatus.BENCHED}
      onClick={handleTicketPress(actions)}
    >
      <div className="header">
        <div className="createdOnWrapper">
          {createdOn?.label && (
            <Typography variant={TypographyVariants.TAG_BOLD}>
              {createdOn.label}
            </Typography>
          )}
          {createdOn?.value && (
            <Typography variant={TypographyVariants.TAG_REGULAR}>
              {createdOn.value}
            </Typography>
          )}
        </div>
        {statusTag && (
          <div className="statusWrapper">
            <div className="statusDot" />
            <Typography
              variant={TypographyVariants.TAG_BOLD}
              customClassName="statusText"
            >
              {statusTag}
            </Typography>
          </div>
        )}
      </div>
      <div className="body">
        {icon && <OptimizedImage customClassName="chatIcon" source={icon} />}
        {title && (
          <Typography
            variant={TypographyVariants.BODY_BASE_REGULAR}
            customClassName="chatTitle"
          >
            {title}
          </Typography>
        )}
        <div className="rightArrow">
          <ChevronUp />
        </div>
      </div>
      {(status === HelpAndSupportTicketStatus.AUTO_TERMINATED ||
        status === HelpAndSupportTicketStatus.RESOLVED) &&
      !+(ratingData?.rating || '0') ? (
        <div className="ratingWrapper">
          <Typography
            variant={TypographyVariants.TAG_REGULAR}
            customClassName="ratingTitle"
          >
            {ratingData?.label}
          </Typography>
          <RatingStarSelector
            width={24}
            height={24}
            gap="var(--dls-size-4)"
            totalRating={ratingData?.totalRating}
            onRatingSelect={handleRatingSelection}
          />
        </div>
      ) : null}
    </StyledSupportTicketListingItem>
  )
}

export default SupportTicketListingItem
