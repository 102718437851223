import {useCartStore} from '@mosaic-wellness/redux-action-library'
import {useGenericActions} from '../../hooks'
import isEmpty from 'lodash/isEmpty'
import {useCallback, useMemo} from 'react'
import {StickyCartSummaryState} from './StickyCartSummary.interface'

export const useStickyCartSummary = (widgetData: {
  showSubTitle: boolean
}): StickyCartSummaryState => {
  // TODO: Remove cart store
  const {cart} = useCartStore()
  const {genericAction} = useGenericActions()
  const {checkoutInfo} = cart || {}
  const {milestoneTier} = checkoutInfo || {}

  const {
    cta,
    productImage = '',
    title = '',
    subtitle = '',
  } = milestoneTier || {}

  const {label = '', action = '', actionData = {}} = cta || {}
  const {showSubTitle = true} = widgetData || {}

  const isMilestoneTierEmpty = useMemo(
    () => isEmpty(milestoneTier),
    [milestoneTier]
  )

  const handleCtaClick = useCallback(() => {
    genericAction({
      actionName: action,
      params: {
        ...(actionData || {}),
      },
    })
  }, [action, actionData, genericAction])

  return {
    productImage,
    title,
    subtitle,
    showSubTitle,
    label,
    isMilestoneTierEmpty,
    handleCtaClick,
  }
}
