import styled from 'styled-components'

export const StyledSupportMenuItems = styled.div`
  background: var(--dls-white-color);
  border-radius: var(--dls-size-16);
  overflow: hidden;
  .menuItemSeperator {
    border-bottom: var(--dls-size-1) solid var(--dls-divider-light-color);
  }
  .accordion-container {
    padding: var(--dls-size-16);
    .content-text {
      display: none;
    }
    .accordion-content {
      color: var(--dls-secondary-text-colors);
      padding-top: var(--dls-size-4);
      .accordion-body-text {
        margin-bottom: var(--dls-size-16);
      }
      .accordion-chat-cta {
        margin-bottom: var(--dls-size-4);
      }
    }
  }
`
