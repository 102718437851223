import {useUserStore} from '@mosaic-wellness/redux-action-library'
import isEmpty from 'lodash/isEmpty'
import {useCallback, useEffect, useRef} from 'react'
import {
  getRandomIdFromCookie,
  validateExperimentalId,
} from 'src/utils/getOrSetCookie'
import useGetClientSideExperiments from '../getClientSideExperiments/getClientSideExperiments'

const useInitializeClientSideExperiments = () => {
  const {
    user: {isLoggedIn},
  } = useUserStore()

  const [, {updateDataLayerAndStore}] = useGetClientSideExperiments()

  const isCookieBeingFetched = useRef(false)

  const initializeClientSideExperiments = useCallback(
    async (cookieName: string, experiments: Record<string, any>) => {
      if (!isCookieBeingFetched.current) {
        isCookieBeingFetched.current = true
        const randomId = await getRandomIdFromCookie(cookieName)
        if (!isEmpty(randomId)) {
          const enabledExperiment = await validateExperimentalId(randomId)
          if (experiments?.key) {
            await updateDataLayerAndStore([
              {key: experiments.key, value: enabledExperiment},
            ])
          }
          isCookieBeingFetched.current = false
          return enabledExperiment
        }
        isCookieBeingFetched.current = false
      }
      return false
    },
    [updateDataLayerAndStore]
  )

  useEffect(() => {
    !isLoggedIn &&
      initializeClientSideExperiments('random_experiment_id', {
        key: 'poweredByGokwikShown',
      })
  }, [isLoggedIn])
}

export default useInitializeClientSideExperiments
