import React, {useCallback} from 'react'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import {FloatingIslandNudgesContainer} from './FloatingIslandNudges.styles'
import StyledIconWrapper from 'src/components/IconWrapper/StyledIconWrapper'
import {UpDownArrow} from '@web-components'

function FloatingIslandNudges(props) {
  const {state = {}, actions = {}} = props
  const {
    text = '',
    link = '',
    backgroundColor = '',
    textColor = '',
    position = '',
    type = '',
  } = state
  const {onNudgeClick = noop} = actions

  const handleNudgeClick = useCallback(() => {
    onNudgeClick({link, type})
  }, [link, onNudgeClick, type])

  if (isEmpty(state)) {
    return null
  }

  return (
    <FloatingIslandNudgesContainer
      /**
       * below className is used in multiple places related to self diagnosis to detect wether
       * floating island is present or not
       *  */
      className="floating-island-nudges"
      backgroundColor={backgroundColor}
      textColor={textColor}
      data-position={position}
    >
      <div className="user-nudge" onClick={handleNudgeClick}>
        <span className="nudge-text">{text}</span>
        <StyledIconWrapper icon={<UpDownArrow rotate={-90} color="white" />} />
      </div>
    </FloatingIslandNudgesContainer>
  )
}

export default FloatingIslandNudges
