import {useCallback, useState} from 'react'
import noop from 'lodash/noop'

import {checkIsApp} from 'src/utils/checkIsApp'
import {useRedirection} from '..'
import {useCommsActiveRoute} from 'src/stores/useCommsActiveRoute/useCommsActiveRoute.store'

function useDaFomoCard() {
  const [showDismissModal, setShowDismissModal] = useState<boolean>(false)
  const isApp = checkIsApp()
  const {data} = useCommsActiveRoute()

  const [{currentConsultVersion = ''}, {handleRedirection = noop}] =
    useRedirection()

  const toggleDismissModal = useCallback(
    () => setShowDismissModal((isVisible) => !isVisible),
    []
  )

  const onFomoCardSecondaryCtaClick = useCallback(() => {
    if (isApp) {
      handleRedirection({
        action: 'SPA_LINK',
        link: data?.link,
        version: currentConsultVersion,
      })
      return
    }
    window.location.href = window.location.origin
  }, [currentConsultVersion, data?.link, handleRedirection, isApp])

  return [
    {showDismissModal},
    {toggleDismissModal, onFomoCardSecondaryCtaClick},
  ] as const
}

export {useDaFomoCard}
