import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'

import {CLIENT_ENV} from 'src/constants/clientEnv'
import {
  HttpClient,
  useLoadingStore,
} from '@mosaic-wellness/redux-action-library'
import {useState, useCallback, useMemo, useEffect} from 'react'
import {useGenericCta, useSessionStorage} from 'src/hooks'
import {getMappedCategory} from '../directAssessment/directAssessment.utils'
import useSATracking from '../tracking/useSATracking'
import {
  getCategoryFromAppointmentCategory,
  getEncodedUrl,
  getMappedProductsForFormCartNative,
} from 'src/utils'
import {IUseSaReportProps} from './selfAssessment.interface'
import {EVENT_MAP} from 'src/analytics/eventMap'
import {checkIsApp} from 'src/utils/checkIsApp'
import {useGenericCtaV2} from '../useGenericCtaV2'
import {isNativeConsultVersion} from 'src/utils/isNativeConsultVersion'
import {useIsAppointmentScheduled} from '../useIsAppointmentScheduled'
import {useDaImageUpload} from '../directAssessment'
import {useFutureAppointments} from '../consult/useFutureAppointments/useFutureAppointments'
import {isBrandLJ} from 'src/utils/isBrandLJ'
import {useSwipeToBookData} from '../consult/useSwipeToBookData/useSwipeToBookData'
import {toast} from 'src/toast'
import {analytics} from 'src/analytics'
import {useUserContent} from '../useUserContent/useUserContent'

const BRAND = CLIENT_ENV.NEXT_PUBLIC_BRAND || 'mm'

const useSaReport = (props: IUseSaReportProps) => {
  const {consultConfigData = {}, reportVersion, theme} = props
  const {miniPdpBottomSheetCtaConfig = {}} = consultConfigData || {}
  const isApp = checkIsApp()

  /*States */
  const [showModal, setShowModal] = useState(false)
  const [showImageUpload, setShowImageUpload] = useState(false)
  const [reportData, setReportData] = useState<any>({})
  const [isReportDataLoading, setIsReportDataLoading] = useState<boolean>(false)
  const [isReportError, setIsReportError] = useState<Error | null>(null)
  const [formId, setFormId] = useState<string | null>(null)
  const [consultVersion, setConsultVersion] = useState<string | null>(null)
  const [patientId, setPatientId] = useState<string | null>(null)

  /*Custom Hooks */
  const [{futureAppointmentData}, {checkFutureAppointments}] =
    useFutureAppointments()
  const [daImageUploadState, daImageUploadActions] = useDaImageUpload()
  const {
    onSelfAssessmentReportViewed,
    onSelfAssessmentAppointmentViewed,
    onSelfAssessmentAppointmentSubmitted,
    onSelfAssessmentAppointmentBooked,
    onSelfAssessmentPlanClicked,
    pushReportToDataLayer,
    onSaApiError,
  } = useSATracking()
  const {
    state: {
      isLoggedIn = false,
      firstName = '',
      lastName = '',
      phoneNumber = '',
      email = '',
      slotData = null,
      bookData = null,
      bookError = null,
      isLoading = false,
    },
    actions: {
      setShowLoginModal = noop,
      getSlotsData = noop,
      bookSlotsData = noop,
    },
  } = useSwipeToBookData({isUsedInWebView: isApp})
  const {handleCta} = useGenericCta()
  const {handleCtaV2 = noop} = useGenericCtaV2()
  const {isUserHydration} = useLoadingStore()
  const primaryUtmSource = useSessionStorage('primary_utm_source')
  const secondaryUtmSource = useSessionStorage('secondary_utm_source')
  const {setIsAppointmentScheduled} = useIsAppointmentScheduled()
  const [_, {getUserContent: getFloatingIslandNudgeData}] = useUserContent()

  const {
    appointmentDetails,
    scheduleToken = '',
    appointmentId = '',
    category = 'Hair',
    type = '',
    imageUploaded = false,
  } = futureAppointmentData || {}
  const {date = '', time = ''} = appointmentDetails || {}
  const {
    scoreCard = {},
    products = [],
    rxCategory = '',
    report = {},
  } = reportData

  /*Callbacks */
  const handleCTAClick = useCallback(() => {
    setShowModal(true)
    setShowImageUpload(false)
    onSelfAssessmentAppointmentViewed()
  }, [onSelfAssessmentAppointmentViewed])

  const handleClose = useCallback(() => {
    setShowModal(false)
    setShowImageUpload(false)
  }, [])

  const onUploadImageClick = useCallback(() => {
    setShowImageUpload(true)
  }, [])

  const handleCloseImageUpload = useCallback(() => {
    setShowImageUpload(false)
  }, [])

  const primaryRedirect = useCallback(() => {
    handleClose()
    handleCloseImageUpload()
  }, [handleClose, handleCloseImageUpload])

  const miniPdpBottomSheetCtaAction = useMemo(() => {
    return (
      reportData?.pageData?.widgetData?.TREATMENT_RECOMMENDATIONS
        ?.stickyFooterData?.primaryAction?.primaryCta || {}
    )
  }, [reportData])

  const handleTreatmentProductClick = useCallback(
    (product: any) => {
      const {urlKey = '', name = ''} = product || {}
      handleCta({
        action: 'OPEN_MINI_PRODUCT_PAGE_BOTTOM_SHEET',
        actionData: {
          urlKey: urlKey,
          name,
          source: 'Form Report',
          cta: {
            ...miniPdpBottomSheetCtaConfig,
            clickAction: miniPdpBottomSheetCtaAction,
          },
        },
      })
    },
    [handleCta, miniPdpBottomSheetCtaAction, miniPdpBottomSheetCtaConfig]
  )

  const callFetchReport = useCallback(async () => {
    const axios = HttpClient.getAxiosInstance()

    try {
      setIsReportDataLoading(true)
      const {data} = await axios.post(
        `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}consult/form/fetch-report`,
        {
          ...(formId && {formId}),
          ...(patientId && {patientId}),
          ...(reportVersion && {
            reportVersion,
            theme,
          }),
        }
      )

      if (data?.data?.data) {
        setReportData(data?.data?.data)
        setIsReportDataLoading(false)
      }
    } catch (error: any) {
      const toastMessage = 'No report available for you with this report ID'
      if (error?.response) {
        const {status, statusText = '', data = {}} = error.response
        const errorMessage = data?.message || statusText

        onSaApiError({
          status,
          errorMessage,
          api: '/consult/form/fetch-report',
          toastMessage,
        })
      }
      toast('warning', toastMessage)
      handleCta({action: 'ROUTER_REPLACE', link: '/'})
    } finally {
      setIsReportDataLoading(false)
    }
  }, [patientId, formId, reportVersion, theme, onSaApiError])

  const onViewPersonalizedPlanClicked = useCallback(() => {
    onSelfAssessmentPlanClicked()
    const link = `/checkout-v2?add-to-cart=${getEncodedUrl(products)}`
    analytics.trigger(EVENT_MAP.UPDATE_CLEVER_TAP_USER, {
      key: 'Regime',
      value: link,
    })

    if (isBrandLJ && isApp) {
      /** We receive the product in an array format, but to redirect to the app (Cart), we follow a format where the product is mapped with its quantity (key:value). Hence, we assign a default quantity of 1. */
      const productObj = products.reduce((accumulator, currentValue) => {
        accumulator[currentValue] = 1
        return accumulator
      }, {})
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          actions: 'APP_NAV',
          actionData: {
            path: 'ATCInterstitial',
            products: productObj,
          },
        })
      )
      return
    }

    if (
      isApp &&
      isNativeConsultVersion({currentConsultVersion: consultVersion})
    ) {
      const {
        scoreCard,
        products = [],
        diagnosisCard,
        rxCategory = [],
      } = reportData || {}
      const {score = '', condition = ''} = scoreCard || {}
      const {diagnosis = ''} = diagnosisCard || {}
      const analyticsData = {
        category: getCategoryFromAppointmentCategory(
          Array.isArray(rxCategory) ? rxCategory[0] : rxCategory
        ),
        phoneNumber,
        utmSource: primaryUtmSource || secondaryUtmSource,
        overAllScore: score,
        productIds: !isEmpty(products) ? products.join(',') : '',
        condition: condition,
        diagnosis: diagnosis,
      }
      handleCtaV2({
        action: 'APP_NAV',
        data: {
          appNavigationData: {
            path: 'ATCInterstitial',
            products: getMappedProductsForFormCartNative(products),
            doctor: true,
            analyticsData,
          },
        },
      })
      return
    }
    handleCta({
      action: 'SPA_LINK',
      link,
    })
  }, [
    onSelfAssessmentPlanClicked,
    products,
    isApp,
    consultVersion,
    handleCta,
    reportData,
    phoneNumber,
    primaryUtmSource,
    secondaryUtmSource,
    handleCtaV2,
  ])

  /*Memoized Variables */

  const uploadImageProps = useMemo(() => {
    return {
      state: {
        ...consultConfigData?.imageUploadScreen,
        category: getMappedCategory(category as string)?.toLowerCase(),
        scheduleToken,
        imageUploaded,
        showSkipUploadSection: false,
        ...daImageUploadState,
      },
      actions: {
        ...daImageUploadActions,
        primaryRedirect,
      },
    }
  }, [
    consultConfigData?.imageUploadScreen,
    category,
    scheduleToken,
    imageUploaded,
    daImageUploadState,
    daImageUploadActions,
    primaryRedirect,
  ])

  const scheduleScreenProps = useMemo(() => {
    return {
      state: {
        scheduleScreenUIData: consultConfigData?.scheduleScreen,
        slotData,
        bookSlotsData,
        bookData,
        bookError,
        isLoggedIn,
        setShowLoginModal,
        firstName,
        lastName,
        phoneNumber,
        email,
        isLoading,
        showInfoCards: false,
        brand: BRAND.toUpperCase(),
        source: 'F',
        category: rxCategory,
        recommendedProducts: products,
        sourceId: formId,
        secondaryUtmSource,
        primaryUtmSource,
      },
      actions: {
        getSlotsData,
        bookSlotsData,
        setShowLoginModal,
        toast,
        onSelfAssessmentAppointmentSubmitted,
        setIsAppointmentScheduled,
        getFloatingIslandNudgeData,
      },
    }
  }, [
    consultConfigData?.scheduleScreen,
    slotData,
    bookSlotsData,
    bookData,
    bookError,
    isLoggedIn,
    setShowLoginModal,
    firstName,
    lastName,
    phoneNumber,
    email,
    isLoading,
    rxCategory,
    products,
    formId,
    getSlotsData,
    onSelfAssessmentAppointmentSubmitted,
    primaryUtmSource,
    secondaryUtmSource,
    setIsAppointmentScheduled,
    getFloatingIslandNudgeData,
  ])

  const activeScoreCardData = useMemo(() => {
    if (!isEmpty(appointmentDetails)) {
      if (type === 'IA') {
        return 'IA'
      }
      return 'SCHEDULED'
    }
    return 'BOOK'
  }, [appointmentDetails, type])

  const appointmentDetailsCardData = useMemo(() => {
    return {
      title: 'Appointment Details',
      concern: category,
      appointmentId,
      appointmentInfo: `${date} at ${time}`,
      appointmentDetailsCardCTA: 'Upload Photos',
    }
  }, [appointmentId, category, date, time])

  const scoreCardProps = useMemo(() => {
    return {
      state: {
        ...scoreCard,
        scoreCardCTA: scoreCard?.scoreCardCta?.[activeScoreCardData],
        showModal,
        variant: activeScoreCardData,
        showImageUpload,
        appointmentDetails: appointmentDetailsCardData,
        scheduleScreenProps,
        uploadImageProps,
        rxCategory,
      },
      actions: {
        handleCTAClick,
        handleClose,
        onUploadImageClick,
        handleCloseImageUpload,
      },
    }
  }, [
    rxCategory,
    scoreCard,
    activeScoreCardData,
    showModal,
    showImageUpload,
    appointmentDetailsCardData,
    scheduleScreenProps,
    uploadImageProps,
    handleCTAClick,
    handleClose,
    onUploadImageClick,
    handleCloseImageUpload,
  ])

  const triggerRefreshHomePageOnApp = useCallback(() => {
    if (isApp) {
      //@ts-ignore
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          actions: 'RELOAD_HOMEPAGE',
          actionData: {
            isWebView: true,
          },
        })
      )
    }
  }, [isApp])

  useEffect(() => {
    if ((formId && isLoggedIn) || patientId) {
      callFetchReport()
    }
  }, [callFetchReport, formId, isLoggedIn, patientId])

  useEffect(() => {
    if (isLoggedIn && checkFutureAppointments) {
      checkFutureAppointments()
    }
  }, [isLoggedIn, checkFutureAppointments])

  useEffect(() => {
    if (bookData) {
      onSelfAssessmentAppointmentBooked({
        //@ts-expect-error
        imageAvailable: bookData?.imageAvailable,
      })
      toast('success', 'Appointment successfully booked')
      handleClose()
      if (checkFutureAppointments) {
        checkFutureAppointments()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookData])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const formId = urlParams.get('formId')
    const consultVersion = urlParams.get('consultVersion')
    const patentId = urlParams.get('patientId')

    if (formId) {
      setFormId(formId)
    }
    if (consultVersion) {
      setConsultVersion(consultVersion)
    }
    if (patentId) {
      setPatientId(patentId)
    }
  }, [])

  useEffect(() => {
    const pricingInReport =
      reportData?.pageData?.widgetData?.TREATMENT_RECOMMENDATIONS
    pushReportToDataLayer({
      score: reportData?.scoreCard?.score,
      products: reportData?.products,
      condition: reportData?.scoreCard?.condition,
      diagnosis: reportData?.diagnosisCard?.diagnosis,
      diagnosisData: reportData?.diagnosisCard?.diagnosisData,
      pricing: {
        beforeDiscount: pricingInReport?.pricingBeforeDiscount?.amount,
        afterDiscount: pricingInReport?.pricingAfterDiscount?.amount,
      },
    })

    if (!isEmpty(reportData)) {
      //to refetch nudge data on successful fetch of a form report placement
      getFloatingIslandNudgeData()
      onSelfAssessmentReportViewed()
      triggerRefreshHomePageOnApp()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData])

  return [
    {
      scoreCardProps,
      firstName,
      isLoggedIn,
      bookData,
      reportData,
      isReportDataLoading,
      report,
      category: getCategoryFromAppointmentCategory(rxCategory),
      isUserHydration,
      error: isReportError,
    },
    {
      toast,
      handleCTAClick,
      handleClose,
      onUploadImageClick,
      handleCloseImageUpload,
      primaryRedirect,
      checkFutureAppointments,
      onViewPersonalizedPlanClicked,
      handleCta,
      handleTreatmentProductClick,
    },
  ]
}

export {useSaReport}
