import SearchBar from './SearchBar/SearchBar'
import useSearchInputWithRecommendation from './useSearchInputWithRecommendation'
import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {SearchInputWithRecommendationProps} from './SearchInputWithRecommendation.types'
import SearchResultProductList from './SearchResultProductList/SearchResultProductList'
import {SearchInputWithRecommendationContainer} from './SearchInputWithRecommendation.styles'
import SearchResultsLoader from './SearchResultsLoader'
import noop from 'lodash/noop'
import {useCartStore} from '@mosaic-wellness/redux-action-library'
import {useMemo} from 'react'

const SearchInputWithRecommendation = (
  props: BaseWidgetProps<SearchInputWithRecommendationProps>
) => {
  const {widgetData} = props
  const {searchHeaderConfig} = widgetData || {}

  const [
    {
      searchBarData,
      searchResultData,
      isSearchResultEmpty,
      showProductList,
      isLoading,
      searchBarRef,
    },
    {},
  ] = useSearchInputWithRecommendation(props)
  const {cart} = useCartStore()
  const {checkoutInfo} = cart || {}
  const {milestoneTier} = checkoutInfo || {}

  const {cta = ''} = milestoneTier || {}

  const {cartIcon = ''} = searchHeaderConfig || {}
  const {handleCartClick = noop} = searchResultData || {}

  const cartItemCount = useMemo(() => {
    return cart?.totalItems
      ? cart.totalItems
      : cart?.cartCount
      ? cart.cartCount
      : 0
  }, [cart])

  return (
    <SearchInputWithRecommendationContainer
      isSearchResultEmpty={isSearchResultEmpty}
    >
      <div className="search-bar-proxy-wrapper" />

      <div ref={searchBarRef} className="search-bar-wrapper">
        <div className="content-container">
          <SearchBar {...searchBarData} />
          {!cta && cartIcon ? (
            <div className="cartIcon-container" onClick={handleCartClick}>
              <img
                src={cartIcon}
                alt="cart"
                className="cart-icon-image"
                loading="lazy"
              />
              {cartItemCount > 0 && (
                <span className="cart-badge">{cartItemCount}</span>
              )}
            </div>
          ) : null}
        </div>
      </div>

      {isLoading ? <SearchResultsLoader /> : null}

      {showProductList && !isLoading && (
        <div className="search-result-wrapper">
          <SearchResultProductList {...searchResultData} />
        </div>
      )}
    </SearchInputWithRecommendationContainer>
  )
}

export default SearchInputWithRecommendation
