import CORE_THEME from 'src/components/shared/src/themeToUse'

import {GlobalStyle} from 'layout/Basic'
import {Toaster} from 'react-hot-toast'
import {useCallback, useMemo, useRef, useState} from 'react'
import UTMTracking from 'src/components/UTMTracking'
import {createConfiguration} from 'src/action-library/actionLibraryConfig'
import {ActionLibrary} from '@mosaic-wellness/redux-action-library'
import {AppProps} from 'next/app'
import DeviceInfo from 'src/components/shared/src/ComponentsV2/Context/DeviceInfo'
import InternationalizationContextProvider from 'src/components/shared/src/ComponentsV2/Context/InternationalizationContext'
import {useRouter} from 'next/router'

import {useHeaderAndFooter} from 'src/hooks/useHeaderAndFooter'
import PageCallout from 'src/components/utility/Callout'
import {useBoot} from 'src/hooks/boot/useBoot'
import dynamic from 'next/dynamic'
import {useSetPrimaryUtmSource} from 'src/hooks'
import SessionHandling from 'src/components/Sessionhandling'
import PageLoading from 'src/components/PageLoading'
import AppConfigProvider from 'src/context/AppConfigContext'
import Head from 'next/head'
import {checkIsApp, checkIsAppViaParam} from 'src/utils/checkIsApp'
import FloatingIslandNudgesBar from 'src/components/FloatingIslandNudgesBar'
import CartLoadingContextProviderWithProps from 'src/context/CartLoadingContextProviderWithProps'
import WebViewHeaderWrapper from 'src/components/WebViewHeaderWrapper'
import FloatingSelfDiagnosisToastWrapper from 'src/components/FloatingSelfDiagnosisToastWrapper'
import {themeToUse} from 'src/components/shared/src/dls/theme/generateTheme'
import LoginContainer from 'src/containers/Login'
import Boot from 'src/components/Boot'
import ATCBottomSheetContainer from 'src/containers/ATCBottomSheetContainer'
import {QueryClient, QueryClientProvider} from 'react-query'
import CoreContextFeMono from 'src/context/CoreContextFeMono'
import MiniProductPageBottomSheetContainer from 'src/containers/MiniProductPageBottomSheetContainer'
import {CLIENT_ENV} from 'src/constants/clientEnv'
const CUSTOM_ANALYTICS_ENABLED = CLIENT_ENV.NEXT_PUBLIC_CUSTOM_ANALYTICS || ''
const COUNTRY = CLIENT_ENV.NEXT_PUBLIC_COUNTRY || 'IN'

const Analytics = dynamic(
  () =>
    import(/* webpackChunkName: "analytics" */ '../src/components/Analytics'),
  {
    ssr: false,
  }
)

const SearchComponent = dynamic(
  () =>
    import(
      /* webpackChunkName: "search" */ '../src/components/Search/SearchComponent'
    ),
  {
    ssr: false,
  }
)

const Footer = dynamic(
  () => import(/* webpackChunkName: "footer" */ '../src/components/Footer'),
  {
    ssr: true,
  }
)

const Navbar = dynamic(
  () => import(/* webpackChunkName: "navbar" */ '../src/components/Nav'),
  {
    ssr: true,
  }
)

const TransparentNavbar = dynamic(
  () => import('../src/components/TransparentNavbar'),
  {ssr: true}
)

const BRAND = process.env.NEXT_PUBLIC_BRAND || 'lj'

function MyApp({Component, pageProps, router: ogRouter, ...rest}: AppProps) {
  const {pageData, pageCallout, isRept} = pageProps || {}
  const {headerAndFooterData} = pageData || {}
  const headerAndFooterDataRef = useRef(headerAndFooterData)
  //@ts-expect-error
  const {device, err} = rest
  const {
    navBarData = {},
    footerData = {},
    transparentHeaderData = {},
    wellnessAssessment = {},
    userFeedBack = {},
    search = {},
    pagesThatNeedLogin: protectedRouteData,
    consultationNudgeData = {},
    favicon = {},
    flags = {},
    appConfig = {},
    webviewHeaderConfig = {},
    loginData = {},
  } = headerAndFooterDataRef?.current || {}

  const {href: faviconLink} = favicon || {}
  const {showSearchPage = false} = flags || {}

  const router = useRouter()

  const isApp = pageProps?.isApp || checkIsApp() || checkIsAppViaParam()

  useBoot()
  const {showHeader, showFooter, showSideCart} = useHeaderAndFooter()

  const configuration = useMemo(
    () => createConfiguration(router, {}, isApp),
    [router, isApp]
  )

  // TODO: AE - WHY do  we have different themes for LJ and MM?
  const [queryClient] = useState(new QueryClient())

  const [showSearchModal, setShowSearchModal] = useState(false)

  useSetPrimaryUtmSource()

  const toggleSearchModal = useCallback(() => {
    if (showSearchPage) return router.push('/search')
    setShowSearchModal((showSearchModal) => !showSearchModal)
  }, [router, showSearchPage])

  const renderNavbar = useCallback(() => {
    switch (BRAND) {
      case 'mm':
      case 'mm-ae':
      case 'mm-co':
      case 'lj':
      case 'lj-co':
      case 'lj-ae':
      case 'lj-sa':
      case 'bw':
      case 'bw-co':
        return (
          <>
            <PageCallout
              pageCallout={pageCallout}
              appConfig={appConfig}
              isRept={isRept}
            />
            <Navbar
              navState={navBarData}
              showHeader={showHeader}
              showSearchModal={showSearchModal}
              showSearchPage={showSearchPage}
              handleShowSearchModal={toggleSearchModal}
              consultationNudgeData={consultationNudgeData}
            />
          </>
        )

      case 'rl-in':
      case 'rl-us':
      case 'bw-ae':
        return (
          <TransparentNavbar
            transparentHeaderData={transparentHeaderData}
            showHeader={showHeader}
          />
        )

      default:
        return null
    }
  }, [
    pageCallout,
    appConfig,
    isRept,
    navBarData,
    showHeader,
    showSearchModal,
    showSearchPage,
    toggleSearchModal,
    consultationNudgeData,
    transparentHeaderData,
  ])

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Head>
          <link rel="icon" href={faviconLink} type="image/x-icon"></link>
        </Head>
        <CORE_THEME theme={themeToUse} />
        <GlobalStyle />
        <ActionLibrary config={configuration} isForWeb>
          <AppConfigProvider value={appConfig}>
            <CoreContextFeMono>
              <Toaster containerClassName="non-clickable-toaster" />
              <Boot isApp={pageProps?.isApp} />
              <InternationalizationContextProvider country={COUNTRY}>
                <DeviceInfo presetDeviceInfo={{device}}>
                  {!isApp && <>{renderNavbar()}</>}
                  <LoginContainer loginData={loginData} />
                  <FloatingIslandNudgesBar />
                  <FloatingSelfDiagnosisToastWrapper />
                  <WebViewHeaderWrapper config={webviewHeaderConfig} />
                  {showSearchModal && (
                    <SearchComponent
                      searchBarData={search}
                      handleHideSearchClicked={toggleSearchModal}
                    />
                  )}
                  <div className="nav_bar_partition"></div>
                  <PageLoading>
                    <CartLoadingContextProviderWithProps>
                      <Component
                        err={err} // this supports any error that we need handle
                        {...pageProps}
                        key={router.asPath}
                        wellnessAssessment={wellnessAssessment}
                        userFeedBack={userFeedBack}
                        protectedRouteData={protectedRouteData}
                        flags={flags}
                      />
                      <ATCBottomSheetContainer />
                      <MiniProductPageBottomSheetContainer />
                    </CartLoadingContextProviderWithProps>
                  </PageLoading>
                  {!isApp && showFooter && <Footer footerData={footerData} />}
                  {CUSTOM_ANALYTICS_ENABLED && <Analytics />}
                  <SessionHandling />
                </DeviceInfo>
              </InternationalizationContextProvider>
              <UTMTracking />
            </CoreContextFeMono>
          </AppConfigProvider>
        </ActionLibrary>
      </QueryClientProvider>
    </>
  )
}

export default MyApp
