import {CLIENT_ENV} from 'src/constants/clientEnv'
import {getAnalyticsData} from './thankYouV2/thankYouV2.util'

export const getPdpConversionData = (dLayer: any) => {
  const {
    isLoggedIn = false,
    phoneNumber = '',
    repeatUser = false,
    email = '',
  } = dLayer?.user || {}

  return {
    currency: CLIENT_ENV.NEXT_PUBLIC_CURRENCY_CODE || 'INR',
    new_customer: isLoggedIn && repeatUser ? 'false' : 'true',
    user_data: {
      email,
      phone_number: phoneNumber,
    },
  }
}

export const getAtcConversionData = (dLayer: any) => {
  const {
    isLoggedIn = false,
    phoneNumber = '',
    repeatUser = false,
    email = '',
  } = dLayer?.user || {}

  return {
    currency: CLIENT_ENV.NEXT_PUBLIC_CURRENCY_CODE || 'INR',
    new_customer: isLoggedIn && repeatUser ? 'false' : 'true',
    user_data: {
      email,
      phone_number: phoneNumber,
    },
  }
}

export const getPurchaseConversionData = (dLayer: any, data) => {
  const {
    isLoggedIn = false,
    phoneNumber = '',
    repeatUser = false,
    email = '',
  } = dLayer?.user || {}
  const processedData = getAnalyticsData(data)

  return {
    currency: CLIENT_ENV.NEXT_PUBLIC_CURRENCY_CODE || 'INR',
    new_customer: isLoggedIn && repeatUser ? 'false' : 'true',
    value: processedData?.total,
    currency: 'INR',
    transaction_id: processedData?.orderId,
    user_data: {
      email,
      phone_number: phoneNumber,
    },
  }
}
