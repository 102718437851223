import {create} from 'zustand'

interface ICommsActiveRoute {
  data: Record<string, any>
  updateCommsActiveRoute: (data: Record<string, any>) => void
}

//TODO: Remove this once together with entire consult code
export const useCommsActiveRoute = create<ICommsActiveRoute>((set) => ({
  data: {},
  updateCommsActiveRoute: (data: Record<string, any>) => set({data}),
}))
