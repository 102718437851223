import {useCallback, useEffect, useState, useRef} from 'react'

export const useIntersection = ({
  rootMargin = '0px',
  threshold = 0.3,
  keepObserving = false,
  initialValue = false,
} = {}) => {
  const [isIntersecting, setIsIntersecting] = useState(initialValue)
  const nodeRef = useRef(null)

  const registerSection = useCallback(() => {
    let observer: any
    const elementToObserve = nodeRef?.current
    if (!elementToObserve) {
      return
    }

    if (window.IntersectionObserver) {
      const intersectionObserverOptions = {
        roo: null,
        rootMargin: rootMargin,
        threshold: threshold,
      }
      observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          //  DO something
          if (entry.isIntersecting) {
            if (!keepObserving) {
              observer.disconnect()
            }

            setIsIntersecting(true)
          } else {
            setIsIntersecting(false)
          }
        })
      }, intersectionObserverOptions)

      observer.observe(elementToObserve)
    } else {
      setIsIntersecting(true)
    }

    return () => {
      if (observer) {
        observer.disconnect()
      }
    }
  }, [])

  useEffect(() => {
    registerSection()
  }, [registerSection])

  return {
    isIntersecting,
    nodeRef,
  }
}
