import {Size} from '../size'
import {Color as DLSColor} from '../dlsToUse/colors'
import {LineHeight as DLSLineHeight} from '../dlsToUse/lineHeight'
import {fontFamily as DLSFontFamily} from '../dlsToUse/font'
import {IColor} from '../types'

interface ITheme {
  color: IColor
  lineHeight: string
  sizes: typeof Size
  fontFamily: string
}

export const themeToUse: ITheme = {
  color: DLSColor,
  lineHeight: DLSLineHeight,
  sizes: Size,
  fontFamily: DLSFontFamily,
}
