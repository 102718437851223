import {useCallback, useEffect, useRef, useState} from 'react'
import {useRouter} from 'next/router'
import {
  useUserStore,
  useLoadingStore,
} from '@mosaic-wellness/redux-action-library'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'

import {checkIfDataExpired} from './useFloatingIslandNudges.utils'
import {LOCAL_STORAGE_CONSTANTS, localStorageMethods} from 'src/utils'
import {EVENT_MAP} from 'src/analytics/eventMap'
import {checkIsApp} from 'src/utils/checkIsApp'
import {analytics} from 'src/analytics'
import {useUserContent} from '../useUserContent/useUserContent'

function useFloatingIslandNudges() {
  const [showNudge, setShowNudge] = useState({
    showNudge: false,
    position: '',
  })
  const intervalRef = useRef<null | number>(null)

  const {
    user: {isLoggedIn},
  } = useUserStore()
  const {asPath} = useRouter()
  const [{data}, {updateNudgeDataInStore, getUserContent}] = useUserContent()
  const {isUserHydration = false} = useLoadingStore()
  const isApp = checkIsApp()

  const {routeMapping, nudges} = data || {}

  const getNudgeData = useCallback(
    async ({resetCache} = {resetCache: false}) => {
      const isDataExpired = checkIfDataExpired()

      if (!isDataExpired && !resetCache) {
        const data = localStorageMethods.get('nudgeData')
        updateNudgeDataInStore(data)
        return
      }

      getUserContent()
    },
    [getUserContent, updateNudgeDataInStore]
  )

  const onNudgeCtaClick = useCallback(
    (data) => {
      analytics.trigger(EVENT_MAP.FLOATING_ISLAND_NUDGE_CLICKED, {
        type: showNudge.position === 'top' ? 'Top Bar' : 'Bottom Floating',
        page: asPath,
        ...data,
      })
    },
    [asPath, showNudge.position]
  )

  /* On Route Change calculate if the floating island should be shown, and if yes where it should be shown */
  useEffect(() => {
    if (isEmpty(routeMapping) || !isLoggedIn || isEmpty(nudges) || isApp) {
      return
    }

    let mappingFoundCount = 0
    let analyticsTriggeredForCurrentLoop = false

    for (const i in routeMapping) {
      switch (true) {
        case asPath.split('?')[0] === '/' || asPath.split('#')[0] === '/': {
          setShowNudge({
            showNudge: true,
            position: routeMapping['/'] || 'bottom',
          })

          if (!analyticsTriggeredForCurrentLoop) {
            analytics.trigger(EVENT_MAP.FLOATING_ISLAND_NUDGE_VIEWED, {
              type: 'Bottom Island',
              page: asPath,
              state: nudges ? nudges[0]?.type : '',
            })
            analyticsTriggeredForCurrentLoop = true
          }
          break
        }

        case asPath !== '' && asPath.includes(i): {
          mappingFoundCount += 1

          setShowNudge({
            showNudge: true,
            position: routeMapping[i],
          })

          if (!analyticsTriggeredForCurrentLoop) {
            analytics.trigger(EVENT_MAP.FLOATING_ISLAND_NUDGE_VIEWED, {
              type: routeMapping[i] === 'top' ? 'Top Bar' : 'Bottom Island',
              page: asPath,
              state: nudges ? nudges[0]?.type : '',
            })
            analyticsTriggeredForCurrentLoop = true
          }
          break
        }

        case mappingFoundCount === 0: {
          setShowNudge({
            showNudge: false,
            position: '',
          })
          break
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath, routeMapping])

  /* call Initial getNudgeData on mount, and initiate polling with interval of 5 minutes to refetch the data and invalidate the cache */
  useEffect(() => {
    if (!isUserHydration) {
      if (isLoggedIn) {
        getNudgeData()

        intervalRef.current = window.setInterval(
          () => getNudgeData({resetCache: true}),
          300000
        )
      } else {
        localStorageMethods.remove('nudgeData')
        setShowNudge({showNudge: false, position: ''})
        updateNudgeDataInStore({routeMapping: {}, nudges: []})
      }
    }

    return () => window.clearInterval(intervalRef.current as number)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, isUserHydration])

  /* On every fetch and refetch of nudge data, store in the cache aka local storage */
  useEffect(() => {
    if (!isEmpty(routeMapping) && Array.isArray(nudges)) {
      const data = localStorageMethods.get('nudgeData')
      const isDataEqual =
        isEqual(nudges, data?.nudges) &&
        isEqual(routeMapping, data?.routeMapping)

      if (isDataEqual) {
        return
      }

      localStorageMethods.set({
        keyName: LOCAL_STORAGE_CONSTANTS.NUDGE_DATA,
        value: {
          routeMapping,
          nudges,
          timestamp: new Date().getTime(),
        },
      })
    }
  }, [routeMapping, nudges])

  return {nudgesToShow: nudges, showNudge, onNudgeCtaClick}
}

export {useFloatingIslandNudges}
