import {CLIENT_ENV} from 'src/constants/clientEnv'

const country = CLIENT_ENV.NEXT_PUBLIC_COUNTRY || 'IN'

const phoneNumberRegex = {
  IN: /^(\+91)?(\+91 )?\d{10}$/,
  AE: /^(\+971)?(\+971 )?\d{9}$/,
  SA: /^(\+966)?(\+966 )?\d{7}$/,
  US: /^(\+1)?(\+1 )?\d{10}$/,
}

const phoneNumberSubmitRegex = {
  IN: /^(\\+91)?(\\+91 )?[6-9]\d{9}$/,
  AE: /^(\+971)?(\+971 )?\d{9}$/,
  SA: /^(\+966)?(\+966 )?\d{7}$/,
  US: /^(\+1)?(\+1 )?\d{10}$/,
}

const phoneNumberMaxLength = {
  IN: 10,
  AE: 9,
  US: 10,
}

export const PHONE_NUMBER_MAX_LENGTH = phoneNumberMaxLength[country]
export const regexPattern = {
  PINCODE: /^[1-9][0-9]{5}$/,
  PHONE_NUMBER: phoneNumberRegex[country],
  PHONE_NUMBER_SUBMIT: phoneNumberSubmitRegex[country],
  EMAIL:
    /^[a-zA-Z0-9]+([._]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/,
  FIRST_NAME: /^[a-zA-Z]+$/,
  NAME: /^[a-z ]*$/i,
  CARD_EXPIRY_DATE: /^-?\d*[/]?\d*$/,
  IS_NUMBER: /^$|^\d+$/,
  TEXT: /^.*$/,
  ADDRESS: /^[A-Za-z0-9.'#@%&\/,\s-]*$/i,
  ADDRESS_SUBMIT: /[a-zA-Z]/,
  CITY: /^[A-Za-z0-9-.()&/'\s]*$/i,
  STATE: /^[a-z ]*$/i,
  ZIP_CODE: /^\d{5}(-\d{4})?$/,
}

export const creditDebitCardPattern = [
  /[1-9]/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
]

export const cardExpiryDatePattern = [/[0-9]/, /\d/, '/', /\d/, /\d/]

const validate = (regexp, input) =>
  typeof input === 'string' && input.length <= 320 && regexp.test(input)

export const validateEmail = (input) => {
  return validate(regexPattern.EMAIL, input)
}
