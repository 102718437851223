import React, {CSSProperties, ReactNode} from 'react'

import {StyledIconWrapperProps} from './StyledIconWrapper.interface'

export default function StyledIconWrapper(props: StyledIconWrapperProps) {
  const {
    icon,
    style = {},
    color = 'inherit',
    spin = false,
    className = '',
    size = '0.5px',
  } = props

  const combinedStyle: CSSProperties = {
    ...style,
    color: color || 'inherit',
    fontSize: size || 'inherit',
    animation: spin ? 'spin 1s linear infinite' : undefined,
  }

  return (
    <div style={combinedStyle} className={className || ''}>
      {icon}
    </div>
  )
}
