import {useCallback} from 'react'

import {useUploadImages} from '../consult/useUploadImages/useUploadImages'
import {toast} from 'src/toast'
import {useUserContent} from '../useUserContent/useUserContent'

function useDaImageUpload() {
  const [{uploaded, uploadError, isLoading}, {imageUpload}] = useUploadImages()
  const [{}, {getUserContent: getFloatingIslandNudgeData}] = useUserContent()

  const onImageSizeExceeded = useCallback(({message}) => {
    toast(
      'warning',
      message || 'Image size too large, kindly upload the image below 10 MB.'
    )
  }, [])

  return [
    {
      uploaded,
      uploadError,
      isLoading,
    },
    {
      imageUpload,
      onImageSizeExceeded,
      getFloatingIslandNudgeData,
    },
  ] as const
}

export {useDaImageUpload}
