import styled from 'styled-components'

export const SearchInputContainer = styled.div`
  width: 100%;
  .search-input {
    border-radius: var(--dls-size-12);
    border: var(--dls-size-1) solid var(--dls-divider-light-color);
    background-color: white;

    input {
      font-size: var(--dls-size-16);
      font-weight: 400;
    }
    &:focus-within {
      border-color: var(--dls-brand-primary-color-500);
    }
  }
  .left-icon {
    display: flex;
  }
  .right-icon {
    display: flex;
    margin-right: var(--dls-size-4);
  }
`

export const RightIconContainer = styled.div`
  display: flex;
  margin-right: var(--dls-size-4);
`
