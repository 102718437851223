import {useCallback} from 'react'
import {useGenericActions} from '../../hooks'
import {BaseWidgetProps, GenericAction} from '@mosaic-wellness/fe-types'
import {Product} from '../CategoryProductCardGrid/CategoryProductCard/CategoryProductCard.interface'
import {CategoryProductCardCarouselProps} from './CategoryProductCardCarousel.types'
import {useUpdateItemToCart} from 'src/hooks/updateCart/useUpdateItemToCart'

const useCategoryProductCardCarousel = (
  props: BaseWidgetProps<CategoryProductCardCarouselProps>
) => {
  const {widgetData} = props
  const {source = ''} = widgetData || {}
  const {genericAction} = useGenericActions()
  const {updateItemToCart} = useUpdateItemToCart()
  const handleCardClick = useCallback(
    (product: Product) => {
      genericAction({
        actionName: 'OPEN_PDP',
        params: {...product, source: source},
      })
    },
    [genericAction]
  )
  const handleCtaClick = useCallback(
    (product: Product) => {
      const {action = '', actionData = {}} = product?.cta || {}
      if (!action) return
      genericAction({
        actionName: action,
        params: {...product, source: source, ...(actionData || {})},
      })
    },
    [genericAction]
  )
  const handleUpdateCartItem = useCallback(
    (id: number, sku: string, quantity: number) => {
      updateItemToCart({
        sku,
        quantity,
        isMiniCart: true,
      })
    },
    [updateItemToCart]
  )

  return [
    {},
    {
      handleCardClick,
      handleCtaClick,
      handleUpdateCartItem,
    },
  ] as const
}

export default useCategoryProductCardCarousel
