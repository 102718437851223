import {useCallback} from 'react'
import {useGenericActions} from '../../hooks'
import {BaseWidgetProps, GenericAction} from '@mosaic-wellness/fe-types'
import {ProductListingProps} from './ProductListing.interface'
import {Product} from '../CategoryProductCardGrid/CategoryProductCard/CategoryProductCard.interface'
import {useUpdateItemToCart} from 'src/hooks/updateCart/useUpdateItemToCart'

const useProductListing = (props: BaseWidgetProps<ProductListingProps>) => {
  const {widgetData} = props || {}
  const {headerConfig, source = ''} = widgetData || {}
  const {viewAllConfig} = headerConfig || {}
  const {action: viewAllAction = {} as GenericAction} = viewAllConfig || {}
  const {genericAction} = useGenericActions()
  //TODO: Remove this hook
  const {updateItemToCart} = useUpdateItemToCart()

  const handleCtaClick = useCallback(
    (product: Product) => {
      const {action = '', actionData = {}} = product?.cta || {}
      if (!action) return
      genericAction({
        actionName: action,
        params: {...product, source: source, ...(actionData || {})},
      })
    },
    [genericAction, source]
  )

  const handleCardClick = useCallback(
    (product: Product) => {
      genericAction({
        actionName: 'OPEN_PDP',
        params: {...product, source: source},
      })
    },
    [genericAction, source]
  )

  const handleUpdateCartItem = useCallback(
    (id: number, sku: string, quantity: number) => {
      updateItemToCart({
        sku,
        quantity,
        isMiniCart: true,
      })
    },
    [updateItemToCart]
  )

  const handleReduceQuantity = useCallback(
    (id, sku, quantity) => {
      handleUpdateCartItem(id, sku, quantity)
    },
    [handleUpdateCartItem]
  )

  const handleIncreaseQuantity = useCallback(
    (id, sku, quantity) => {
      handleUpdateCartItem(id, sku, quantity)
    },
    [handleUpdateCartItem]
  )
  return [
    {},
    {
      handleCtaClick,
      handleCardClick,
      handleReduceQuantity,
      handleIncreaseQuantity,
    },
  ]
}

export default useProductListing
