import {useUpdateCart} from '@mosaic-wellness/redux-action-library'
import {useCallback} from 'react'
import {analytics} from 'src/analytics'
import useNavigation from 'src/hooks/useNavigation/useNavigation'

export const useAddToCart = (productData: any) => {
  const {sku} = productData || {}
  const {updateCartItem} = useUpdateCart()

  const handleAddToCart = useCallback(
    async (location?: string, isMiniCart: any = undefined) => {
      analytics.trigger('addToCartClickPd', {
        location,
      })
      updateCartItem({
        info: {
          sku,
          qty: 1,
          action: 'add',
        },
        payload: {
          isMiniCart: isMiniCart,
        },
      })
    },
    [updateCartItem, sku]
  )

  return {
    actions: {
      handleAddToCart,
    },
  }
}

export const useBuyNow = (productData: any) => {
  const {sku} = productData
  const [{}, {navigateTo}] = useNavigation()
  const {updateCartItem} = useUpdateCart()

  const onBuyNowClicked = useCallback((location: string) => {
    analytics.trigger('buyNowClickPd', {
      location,
    })
  }, [])

  const onBuyNowCheckoutClicked = useCallback(
    (location = '', buyNowItem: Record<string, string>) => {
      analytics.trigger('buyNowClickCheckout', {
        location,
        buyNowItem,
      })
    },
    []
  )

  const handleBuyNowAnalyticsTrigger = useCallback(
    (location = '', buyNowItem: any) => {
      if (buyNowItem.sku) {
        onBuyNowCheckoutClicked(location || '', buyNowItem)
      } else {
        onBuyNowClicked(location || '')
      }
    },
    [onBuyNowCheckoutClicked, onBuyNowClicked]
  )

  const handleBuyNowNavigation = useCallback(() => {
    setTimeout(() => {
      navigateTo('checkout-v2?int_cmp=buyNow')
    }, 500)
  }, [navigateTo])

  const handleBuyNow = useCallback(
    (
      location?: string,
      buyNowItem = {},
      isMiniCart: any = false,
      shouldTriggerEvent = true
    ) => {
      const skuValue = buyNowItem.sku ? buyNowItem.sku : sku
      updateCartItem({
        info: {
          sku: skuValue,
          action: 'add',
          qty: 1,
        },
        payload: {
          isMiniCart, //used a flag to bypass mini cart when navigation to checkout on click of buy now to prevent empty cart state
        },
      })
      if (shouldTriggerEvent) handleBuyNowAnalyticsTrigger(location, buyNowItem)
      handleBuyNowNavigation()
    },
    [sku, updateCartItem, handleBuyNowAnalyticsTrigger, handleBuyNowNavigation]
  )

  return [{}, {handleBuyNow, onBuyNowClicked}] as const
}
