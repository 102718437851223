export const getProductDetailsForAnalytics = (product: any) => {
  const {
    actualPrice = '',
    category = '',
    discountText = '',
    discount = '',
    discountedPrice = '',
    is_kit = false,
    is_rx = false,
    name = '',
    price = '',
    sku = '',
    source = '',
    urlKey = '',
    is_pseudo_rx = false,
    url_key = '',
    isRx = false,
    types: {kits = false} = {},
    currentPrice = '',
    discountPercentage = '',
    originalPrice = '',
    productURL = '',
    config,
    query = '',
    isEligibleForQuickDelivery = false,
    quickDeliveryDate = '',
    ogImage,
    servedPincode = '',
    detectedPincode = '',
    detectedCity = '',
  } = product || {}

  const markedPrice = price || originalPrice || actualPrice || ''
  const salePrice = discountedPrice || currentPrice || markedPrice
  const percentageDiscountGiven =
    discountText || discountPercentage || discount || ''
  const productID = urlKey || url_key || productURL || ''
  const isKit = kits || is_kit
  const rxProduct = is_rx || isRx
  const productType = rxProduct ? 'Rx' : is_pseudo_rx ? 'Pseudo Rx' : 'OTC'
  const url = typeof window !== 'undefined' ? `${window.location.href}` : ''

  return {
    productID,
    name,
    sku,
    mrp: markedPrice,
    price: salePrice,
    discountPercentage: percentageDiscountGiven,
    source,
    category,
    rxProduct,
    itemType: isKit ? 'Kit' : 'Product',
    productType,
    url,
    config,
    query,
    isEligibleForQuickDelivery,
    quickDeliveryDate,
    productImage: ogImage,
    servedPincode,
    detectedPincode,
    detectedCity,
  }
}
