import noop from 'lodash/noop'
import {CategoryProductCard} from '../CategoryProductCardGrid/CategoryProductCard'
import {ProductCardGridWrapper} from './ProductCardGrid.styles'
import {ProductCardGridProps} from './ProductCardGrid.types'
import useProductCardGrid from './useProductCardGrid'
import {BaseWidgetComponent} from '../BaseWidget'
import {BaseWidgetProps} from '@mosaic-wellness/fe-types'

const ProductCardGrid = (props: BaseWidgetProps<ProductCardGridProps>) => {
  const {type, widgetData, header, layout, id} = props
  const {
    products,
    enableQuantityIndicator = false,
    noOfColumns = 2,
    showImageBorder = false,
  } = widgetData || {}
  const {
    handleUpdateCartItem = noop,
    handleCtaClick,
    handleCardClick,
  } = useProductCardGrid(widgetData)

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <ProductCardGridWrapper columns={noOfColumns}>
        {products.map((product) => {
          return (
            <div className="product-card" key={product.id}>
              <CategoryProductCard
                handleUpdateCartItem={handleUpdateCartItem}
                handleCtaClick={handleCtaClick}
                handleCardClick={handleCardClick}
                enableQuantityIndicator={enableQuantityIndicator}
                product={product}
                showImageBorder={showImageBorder}
              />
            </div>
          )
        })}
      </ProductCardGridWrapper>
    </BaseWidgetComponent>
  )
}

export default ProductCardGrid
