import React, {useCallback} from 'react'
import {checkIsApp} from 'src/utils/checkIsApp'
import {useRedirectToHomepage} from './useRedirectToHomePage'

function useBackNavigation() {
  const isApp = checkIsApp()
  const {redirectToHomepage} = useRedirectToHomepage()

  const handleBackClick = useCallback(() => {
    if (isApp) {
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          actions: 'NAVIGATE_BACK',
          actionData: {
            isWebView: true,
          },
        })
      )
      return
    }
    if (window.history.length === 1) {
      redirectToHomepage()
    }
    localStorage.setItem('previousUrl', window.location.href)
    window?.history?.back()
    return
  }, [isApp])

  return {handleBackClick}
}

export {useBackNavigation}
