import noop from 'lodash/noop'
import {UpDownArrow} from '@web-components'

function MobileNavArrows(props) {
  const {onClick = noop, disabled = false, ...rest} = props

  return (
    <button onClick={onClick} disabled={disabled} {...rest}>
      <span>
        <UpDownArrow rotate={-90} color="black" />
      </span>
    </button>
  )
}

export default MobileNavArrows
