import {CLIENT_ENV} from 'src/constants/clientEnv'

const BRAND = CLIENT_ENV.NEXT_PUBLIC_BRAND

// TODO: AE - WHY ARE WE HARDCODING BRANDS?
export const isBrandLJ =
  BRAND === 'lj' || BRAND === 'lj-ae' || BRAND === 'lj-sa' || 'lj-co'
export const isBrandInternational =
  BRAND === 'lj-ae' ||
  BRAND === 'lj-sa' ||
  BRAND === 'bw-ae' ||
  BRAND === 'mm-ae' ||
  BRAND === 'rl-us'

export const isUAEBrand =
  BRAND === 'lj-ae' ||
  BRAND === 'lj-sa' ||
  BRAND === 'bw-ae' ||
  BRAND === 'mm-ae'

export const isBrandBW =
  BRAND === 'bw' || BRAND === 'bw-co' || BRAND === 'bw-ae'

export const isCoBrand = () => {
  return BRAND && BRAND.includes('-co')
}
