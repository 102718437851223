import noop from 'lodash/noop'
import {useCallback, useMemo} from 'react'

import Responsive from '../../HOC/Responsive'
import Typography from '../Typography/Typography'
import {SectionContainer} from './Section.styles'
import {UpDownArrow} from '@web-components'

const Section = (props) => {
  const {state = {}, actions = {}, isMobile = false, children = null} = props
  const {
    title = '',
    subtitle = '',
    customClassName = '',
    viewAllLink = '',
    headingClassName = '',
  } = state

  const {onViewAllClick = noop} = actions

  const showViewAll = useMemo(
    () => !isMobile && !!viewAllLink,
    [isMobile, viewAllLink]
  )

  const handleViewAllClicked = useCallback(
    () => onViewAllClick(viewAllLink),
    [onViewAllClick, viewAllLink]
  )

  return (
    <SectionContainer className={`section ${customClassName}`}>
      {title && (
        <Typography
          text={title}
          variant="heading-sm-bold"
          customClassName={headingClassName}
          isDangerouslySet={true}
        />
      )}
      <div className="subtitle-container">
        <Typography
          text={subtitle}
          variant="body-base-regular"
          customClassName="subtitle"
          isDangerouslySet={true}
        />
        {showViewAll && (
          <button onClick={handleViewAllClicked} className="view-all-button">
            View all <UpDownArrow rotate={-90} />
          </button>
        )}
      </div>
      {children}
    </SectionContainer>
  )
}

export default Responsive(Section)
