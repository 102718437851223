import {useCallback, useMemo, useState} from 'react'

import {SupportTicketsListingWidgetData} from './SupportTicketsListing.types'
import {useGenericActions} from '../../hooks'
import {GenericAction} from '@mosaic-wellness/fe-types'

const useSupportTicketsListing = (props: SupportTicketsListingWidgetData) => {
  const {tickets: initialTickets = []} = props || {}
  const [isViewAllClicked, setIsViewAllClicked] = useState(false)
  const {handleGenericActions} = useGenericActions()

  const onFetchAllTicketsClick = useCallback(() => {
    setIsViewAllClicked(true)
  }, [])

  const tickets = useMemo(() => {
    return isViewAllClicked ? initialTickets : initialTickets.slice(0, 1)
  }, [initialTickets, isViewAllClicked])

  const showFetchAllTickets = useMemo(() => {
    return initialTickets.length > 1 && !isViewAllClicked
  }, [initialTickets.length, isViewAllClicked])

  const handleTicketPress = useCallback(
    (ticketPressActions: GenericAction[]) => () => {
      if (ticketPressActions?.length) {
        handleGenericActions(ticketPressActions)
      }
    },
    [handleGenericActions]
  )

  const handleRatingSelection = useCallback(
    (index: number) => (rating: number) => (e: any) => {
      e.stopPropagation()
      const {id, ratingData} = tickets?.[index] || {}
      if (ratingData?.ctaActions?.length) {
        handleGenericActions(ratingData.ctaActions)
      } else {
        handleGenericActions([
          {
            actionName: 'SPA_LINK',
            params: {
              link: `/pci-rating/${id}?isHelpAndSupportTicket=true`,
            },
          },
        ])
      }
    },
    [tickets, handleGenericActions]
  )

  return {
    onFetchAllTicketsClick,
    tickets,
    showFetchAllTickets,
    handleTicketPress,
    handleRatingSelection,
  }
}

export default useSupportTicketsListing
