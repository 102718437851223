import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {
  Pill,
  SearchInputWithRecommendationProps,
} from './SearchInputWithRecommendation.types'
import {BaseWidgetComponent} from '../BaseWidget'
import {Configure, InstantSearch} from 'react-instantsearch-hooks'

import SearchInputWithRecommendation from './SearchInputWithRecommendation'
import useAlgolia from '../../hooks/useAlgolia'
import {useCallback, useEffect, useState} from 'react'
import PillsSection from './PillsSection/PillsSection'
import {useSearchInputStore} from './useSearchInputState.store'
import {useGenericActions} from '../../hooks'
import {SearchBarProxyWrapper} from './SearchInputWithRecommendation.styles'

const SearchInputWithRecommendationWrapper = (
  props: BaseWidgetProps<SearchInputWithRecommendationProps>
) => {
  const {layout, id, type, header, widgetData} = props
  const {pillSectionConfig} = widgetData
  const {searchClient, algoliaIndex} = useAlgolia()

  const searchQuery = useSearchInputStore((store) => store.searchQuery)
  const setSearchQuery = useSearchInputStore((store) => store.setSearchQuery)
  const {genericAction} = useGenericActions()
  const handlePillClick = useCallback(
    (pill: Pill) => {
      const {value = '', action = '', actionData = {}} = pill || {}

      if (!action || action === 'SEARCH') {
        setSearchQuery(value)
      } else {
        genericAction({actionName: action, params: actionData})
      }
      genericAction({
        actionName: 'TRIGGER_EVENTS_FOR_WEB',
        params: {
          event: 'CTA Clicked',
          eventData: {
            pillClicked: value,
            Source: 'search pill',
          },
        },
      })
    },
    [genericAction, setSearchQuery]
  )

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <SearchBarProxyWrapper />
      <div className="search-bar-section-container">
        <InstantSearch
          searchClient={searchClient}
          indexName={algoliaIndex as string}
          stalledSearchDelay={10}
        >
          <Configure analytics clickAnalytics />
          <SearchInputWithRecommendation {...props} />
        </InstantSearch>
      </div>
      {searchQuery ? null : (
        <div className="pill-section-wrapper">
          <PillsSection
            {...pillSectionConfig}
            handlePillClick={handlePillClick}
          />
        </div>
      )}
    </BaseWidgetComponent>
  )
}
export default SearchInputWithRecommendationWrapper
