import React, {useCallback, useMemo} from 'react'
import FloatingSelfDiagnosisToast from 'src/components/shared/src/ComponentsV2/common/FloatingSelfDiagnosisToast'
import {useAppConfigContext} from 'src/context/AppConfigContext'
import {useGenericCta} from 'src/hooks'
import useSelfDiagnosisEvents from 'src/hooks/tracking/useSelfDiagnosisEvents'
import {useUserContent} from 'src/hooks/useUserContent/useUserContent'

/**
 * Wrapper Component for FloatingSelfDiagnosisToast for handling
 * analytics and other  rendering conditions
 */
const FloatingSelfDiagnosisToastWrapper = () => {
  const [{floatingIcon}] = useAppConfigContext()
  const [{data: floatingNudgeData}] = useUserContent()
  const {handleCta} = useGenericCta()

  const isNudgeVisible = useMemo(
    () => floatingNudgeData?.nudges && floatingNudgeData.nudges.length > 0,
    [floatingNudgeData?.nudges]
  )

  const [
    {
      triggerCTAClickedEvent,
      triggerSelfDiagnosisCTAViewEvent,
      triggerSelfDiagnosisNudgeDismissEvent,
    },
  ] = useSelfDiagnosisEvents()

  const handleSelfDiagnosticCta = useCallback(
    ({cta = {}}) => {
      triggerCTAClickedEvent(cta)
      handleCta(cta)
    },
    [triggerCTAClickedEvent, handleCta]
  )

  const handleSelfDiagnosticNudgeClose = useCallback(
    ({cta = {}}) => {
      triggerSelfDiagnosisNudgeDismissEvent(cta)
    },
    [triggerSelfDiagnosisNudgeDismissEvent]
  )

  const handleOnSelfDiagnosisToastLoad = useCallback(
    ({cta}) => {
      triggerSelfDiagnosisCTAViewEvent(cta)
    },
    [triggerSelfDiagnosisCTAViewEvent]
  )

  if (isNudgeVisible || !floatingIcon) {
    return null
  }

  return (
    <FloatingSelfDiagnosisToast
      onClick={handleSelfDiagnosticCta}
      onClose={handleSelfDiagnosticNudgeClose}
      onLoad={handleOnSelfDiagnosisToastLoad}
      data={floatingIcon}
    />
  )
}

export default FloatingSelfDiagnosisToastWrapper
