import {Experiment} from 'src/components/Experiments/Product/types'
import {getProductDetailsForAnalytics} from 'src/utils/getProductDetailsForAnalytics'
import {getQueryParamFromUrl} from 'src/utils/url'

export const processPDCarouselSlide = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {product} = dLayer || {}

  const productData = getProductDetailsForAnalytics(product)
  const dataToReturn = {
    'Product ID': productData.productID,
    'Product Name': productData.name,
    'SKU Code': productData.sku,
    MRP: productData.mrp,
    Price: productData.price,
    'Discount percentage': productData.discountPercentage,
    Source: productData.source,
    Category: productData.category,
    'Rx Product': productData.rxProduct,
    'Item Type': productData.itemType,
    'Product Type': productData.productType,
    URL: productData.url,
    'Content Type': data.isVideo ? 'Video' : 'Image',
    'Card Number': data.index ? data.index : 1,
    'View type': 'PDP View',
  }

  return dataToReturn
}

export const processPDDeliveryCheck = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {}

  return dataToReturn
}

export const processPDOffersCouponViewed = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {code = '', offerNumber = ''} = data || {}

  const {product} = dLayer || {}

  const productData = getProductDetailsForAnalytics(product)
  const dataToReturn = {
    'Product ID': productData.productID,
    'Product Name': productData.name,
    'SKU Code': productData.sku,
    MRP: productData.mrp,
    Price: productData.price,
    'Discount percentage': productData.discountPercentage,
    Source: 'PDP',
    Category: productData.category,
    'Rx Product': productData.rxProduct,
    'Item Type': productData.itemType,
    'Product Type': productData.productType,
    URL: productData.url,
    'Coupon Code': code,
    Number: offerNumber,
  }

  return dataToReturn
}

export const processPDOffersCouponClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {code = ''} = data || {}
  const dataToReturn = {code}

  return dataToReturn
}

export const processPDOffersCouponCopied = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {code = '', offerNumber = ''} = data || {}
  const {product} = dLayer || {}
  const productData = getProductDetailsForAnalytics(product)
  const dataToReturn = {
    'Product ID': productData.productID,
    'Product Name': productData.name,
    'SKU Code': productData.sku,
    MRP: productData.mrp,
    Price: productData.price,
    'Discount percentage': productData.discountPercentage,
    Source: 'PDP',
    Category: productData.category,
    'Rx Product': productData.rxProduct,
    'Item Type': productData.itemType,
    'Product Type': productData.productType,
    URL: productData.url,
    'Coupon Code': code,
    Number: offerNumber,
  }

  return dataToReturn
}

export const processPDSectionScrollIntoView = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {product} = dLayer || {}
  const productData = getProductDetailsForAnalytics(product)
  const {sectionName, position, interactionType} = data || {}
  const dataToReturn = {
    Section: sectionName || '',
    Position: position + 2 || '',
    'Interaction Type': interactionType,
    'Product ID': productData.productID,
    'Product Name': productData.name,
    'SKU Code': productData.sku,
    MRP: productData.mrp,
    Price: productData.price,
    'Discount percentage': productData.discountPercentage,
    Category: productData.category,
    'Rx Product': productData.rxProduct,
    'Item Type': productData.itemType,
    'Product Type': productData.productType,
    URL: productData.url,
  }

  return dataToReturn
}

export const processPDSectionSectionViewed = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {product} = dLayer || {}
  const productData = getProductDetailsForAnalytics(product)
  const {section, position, interactionType} = data || {}
  const dataToReturn = {
    Section: section || '',
    Position: position + 2 || '',
    'Interaction Type': interactionType,
    'Product ID': productData.productID,
    'Product Name': productData.name,
    'SKU Code': productData.sku,
    MRP: productData.mrp,
    Price: productData.price,
    'Discount percentage': productData.discountPercentage,
    Category: productData.category,
    'Rx Product': productData.rxProduct,
    'Item Type': productData.itemType,
    'Product Type': productData.productType,
    URL: productData.url,
  }

  return dataToReturn
}

export const processPDSwitchClick = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {
    product_url_key: data.urlKey,
    base_pdp: dLayer.product.name,
    base_pdp_url_key: dLayer.product.urleKey,
  }

  return dataToReturn
}

export const processPDPView = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {product} = dLayer || {}
  const productData = getProductDetailsForAnalytics(product)

  const dataToReturn = {
    'Product ID': productData.productID,
    'Product Name': productData.name,
    'SKU Code': productData.sku,
    MRP: productData.mrp,
    Price: productData.price,
    'Discount percentage': productData.discountPercentage,
    Category: productData.category,
    'Rx Product': productData.rxProduct,
    'Item Type': productData.itemType,
    'Product Type': productData.productType,
    URL: productData.url,
    utm_source: getQueryParamFromUrl('utm_source'),
    QuickDeliveryEligible: productData.isEligibleForQuickDelivery,
    QuickDeliveryDate: productData.quickDeliveryDate,
    ServedPincode: productData.servedPincode,
    DetectedPincode: productData.detectedPincode,
    DetectedCity: productData.detectedCity,
    'Expected Days': dLayer.product.availableDays,
    ...(dLayer.product.outOfStock && {Status: 'OOS'}),
    ...(product?.isDiscontinuedProduct && {Status: 'Discontinued'}),
    'Product Image': productData.productImage ?? '',
  }

  return dataToReturn
}

export const processPDRatingClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {
    'product name': dLayer.product.name,
    'product ID': window.location.pathname,
    utm_source: getQueryParamFromUrl('utm_source'),
  }

  return dataToReturn
}

export const processSwipeToBook = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {
    'Product Name': dLayer.product.name || '',
    URL: window.location.href,
  }

  return dataToReturn
}

export const processVariantClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {optionType = '', label = ''} = data || {}
  const {product} = dLayer || {}
  const variantData = getProductDetailsForAnalytics(data)
  const productData = getProductDetailsForAnalytics(product)
  const dataToReturn = {
    'Product ID': variantData.productID,
    'Product Name': variantData.name,
    'SKU Code': variantData.sku,
    MRP: variantData.mrp,
    Price: variantData.price,
    'Discount percentage': variantData.discountPercentage,
    'Base Product ID': productData.productID,
    'Base Product Name': productData.name,
    'Base MRP': productData.mrp,
    'Base Price': productData.price,
    'Base Discount percentage': productData.discountPercentage,
    Category: productData.category,
    'Rx Product': variantData.rxProduct,
    'Item Type': variantData.itemType,
    'Product Type': variantData.productType,
    'Variant Product ID': variantData.productID,
    Source: variantData.source,
    'Variant Type': optionType,
    'Variant Text': label,
    URL: productData.url,
    'Self diagnosis category': productData.category || '',
    'Self diagnosis stage': data?.diagnosisData?.stage?.join(',') || '',
    'Self diagnosis concern': data?.diagnosisData?.concerns?.join(',') || '',
    'Self diagnosis severity': data?.diagnosisData?.severity?.join(',') || '',
  }

  return dataToReturn
}

export const processPdpClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const productData = getProductDetailsForAnalytics(data)

  const dataToReturn = {
    'Product ID': productData.productID,
    'Product Name': productData.name,
    'SKU Code': productData.sku,
    MRP: productData.mrp,
    Price: productData.price,
    'Discount percentage': productData.discountPercentage,
    Source: productData.source,
    Category: productData.category,
    'Rx Product': productData.rxProduct,
    'Item Type': productData.itemType,
    'Product Type': productData.productType,
    URL: productData.url,
    query: productData?.query,
  }

  return dataToReturn
}

export const processReorderClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {
    product: data,
  }
  return dataToReturn
}

export const proccessPdFilterClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {filterName, optionsSelected, url, sectionAppliedFor, source} = data
  return {
    filterName,
    optionsSelected: Array.isArray(optionsSelected)
      ? optionsSelected?.join(', ')
      : optionsSelected,
    url,
    sectionAppliedFor,
    source,
  }
}

export const proccessThumbnailClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {position} = data
  return {position}
}

export const processProductExperiments = (data, dLayer) => {
  const {productExperiment = []} = dLayer

  const dataToReturn: Record<string, string> = {}

  productExperiment.forEach((experiment: Experiment) => {
    const {enabled, id, percentage, sectionName, show} = experiment
    const property = `experiment-${sectionName}`
    dataToReturn[property] = `show: ${show}`
  })

  return dataToReturn
}

export const processCTAClicked = (data = {}) => {
  const {label: Text = '', link: URL = '', source: Source = ''} = data
  return {Text, URL, Source}
}

export const processCTAViewed = (data = {}) => {
  const {label: Text = '', link: URL = '', source: Source = ''} = data
  return {Text, URL, Source}
}

export const proccessViewAllClicked = (data: any) => {
  const dataToReturn = {
    Tab: data.tab || '',
  }
  return dataToReturn
}

export const processQuestionAsked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {
    Question: data.question || '',
    phoneNumber: data.phoneNumber || '',
    productName: dLayer.product.name,
  }
  return dataToReturn
}

export const processSelfDiagnosisFloatingIslandClosed = (data = {}) => {
  const {label: Text = '', link: URL = '', source: Source = ''} = data
  return {Text, URL, Source}
}

export const processNotifyMeClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  return {
    'Product ID': dLayer?.product?.urlKey,
    'Product Name': dLayer?.product?.name,
    'Expected Days': dLayer?.product?.availableDays,
  }
}

export const processNotifyMeDone = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  return {
    'Product ID': data?.urlKey,
    'Product Name': data?.name,
    'Expected Days': data?.availableDays,
    Category: data?.category,
    Source: data?.source,
  }
}
