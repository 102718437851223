import {isMobile} from 'src/utils/appPresence/checkIfMobile'
import {CLIENT_ENV} from 'src/constants/clientEnv'
import {getCartDetailsForAnalytics} from 'src/utils/getCartDetailsForAnalytics'
import {logError} from 'src/utils/logger'
import sessionStorageMethods from 'src/utils/sessionStorage/sessionStorageMethods'

const newCartEnabled = CLIENT_ENV.NEXT_PUBLIC_ENABLE_NEW_CART

export const processCheckoutClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {cart, user} = dLayer || {}
  const {location = '', ctaText = ''} = data || {}
  const cartData = getCartDetailsForAnalytics(cart, false, user)
  const dataToReturn = {
    'Product ID': cartData.productIds,
    'Product Name': cartData.productNames,
    'SKU Code': cartData.productSkus,
    'Total Items': cartData.totalItems,
    amount: cartData.totalAmount,
    'Coupon Applied': cartData.isCouponApplied,
    Coupon: cartData.coupon,
    Source: location,
    'Rx Product': cartData.rxStatus,
    'Shipping Charged': !cartData.isShippingFree,
    'Shipping Amount': cartData.shippingValue,
    'Free Gift Items': cartData.freeGiftsCount,
    'Wallet Used': cartData.isWalletApplied,
    'Wallet Balance': cartData.walletPoints,
    URL: cartData.url,
    'Cart Type': cartData.cartType,
    'CTA Label': ctaText,
    'Sub Total': cartData.subTotal,
    'Discount Amount': cartData.discountValue,
  }

  return dataToReturn
}

export const processCartUpSellSelection = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {
    'Prod name': data.name,
    URL: data.productURL,
    SKU: data.sku,
    Price: data.discountedPrice,
  }

  return dataToReturn
}

export const processCartUpSellShow = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {
    'Prod name': data.item.name,
    ID: data.item.id,
    URL: data.item.productURL,
    SKU: data.item.sku,
    Price: data.item.currentPrice,
    'source asset': data.item.image,
  }

  return dataToReturn
}

export const processCartUpSellIncentiveButtonCLicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {cta = '', coupon = ''} = data || {}
  const dataToReturn = {
    CTA: cta,
    'Coupon Code': coupon,
  }
  return dataToReturn
}

export const processCheckoutViewed = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    appointmentExists = false,
    isAvailable = false,
    rxExists = false,
    rxProduct = false,
    slotsAvailable = false,
  } = data || {}

  const {totalAmount = 0} = dLayer?.cart || {}

  const dataToReturn = {
    'Rx Product': rxProduct,
    'Rx Exists': rxExists,
    'Appointment Exists': appointmentExists,
    'IA Available': isAvailable,
    'Slots Available': slotsAvailable,
    amount: totalAmount,
  }

  return dataToReturn
}

export const processOptionalRxOptIn = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {
    'IA Available': dLayer.checkPrescriptionData.iaAvailable ? 'Yes' : 'No',
  }

  return dataToReturn
}

export const processOptionalRxOptOut = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {
    'IA Available': dLayer.checkPrescriptionData.iaAvailable ? 'Yes' : 'No',
  }

  return dataToReturn
}

export const processPayClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  let dataToReturn = {}
  try {
    const {
      source,
      requestType,
      currentChildSelected = 0,
      currentParentSelected = 0,
      subPaymentInfo = 0,
      amount = 0,
    } = data || {}

    const {paymentMethod, paymentType, paymentSubType} = subPaymentInfo || {}

    let isWebView = false
    if (typeof window !== 'undefined' && !window.PaymentRequest && isMobile())
      isWebView = true

    const {cart, user} = dLayer || {}
    const cartData = getCartDetailsForAnalytics(cart, false, user)
    dataToReturn = {
      amount,
      webview: isWebView ? 'In App webview' : 'Browser App',
      'Product ID': cartData.productIds,
      'Product Name': cartData.productNames,
      'SKU Code': cartData.productSkus,
      'Total Items': cartData.totalItems,
      'Coupon Applied': cartData.isCouponApplied,
      Coupon: cartData.coupon,
      'Rx Product': cartData.rxStatus,
      'Shipping Charged': !cartData.isShippingFree,
      'Shipping Amount': cartData.shippingValue,
      'Discount Amount': data.methodType === 'cod' ? 0 : cartData.discountValue,
      'Free Gift Items': cartData.freeGiftsCount,
      'Wallet Balance': cartData.walletPoints,
      'Wallet Used': cartData.isWalletApplied,
      URL: cartData.url,
      'Cart Type': cartData.cartType,
      'Sub Total': cartData.subTotal,
      'Method Type Rank': currentParentSelected + 1,
      'Method Rank': currentChildSelected + 1,
      'Method Type': paymentType,
      Method: paymentSubType,
      'Method partner': paymentMethod,
      Source: source || 'Payment Screen',
      'Payment type': requestType,
    }
  } catch (e) {
    logError(e as Error, {location: 'payClicked'})
    dataToReturn = {
      method: data.method,
      Method: data.method,
      methodType: data.methodType,
      'Method Type': data.methodType,
      error: (e as Error).message || 'Unknown error',
      broken: true,
    }
  }
  return dataToReturn
}

export const processPaymentFailed = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {reason, from, eligibleMethods = {}} = data || {}
  const isWalletPayment = !!sessionStorageMethods.get('isWalletPayment')
  const dataToReturn = {
    Reason: reason,
    Source: from,
    'Payment Type': isWalletPayment ? 'Wallet' : 'Order',
    ...eligibleMethods,
  }

  return dataToReturn
}

export const processPaymentFailedModalClick = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {
    key: data,
  }

  return dataToReturn
}

export const processCodEligibility = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {
    Reason: data.reason,
    'Risk Flag': data.riskFlag,
    Score: data.score,
  }

  return dataToReturn
}

export const processPaymentSelected = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    parentIndex = 0,
    childIndex = 0,
    amount = 0,
    paymentMethod,
    paymentType,
    paymentSubType,
  } = data || {}
  const {cart, user} = dLayer || {}
  const cartData = getCartDetailsForAnalytics(cart, false, user)
  let walletName = data.walletName
  if (!walletName) {
    if (typeof dLayer.payButtonClickData === 'string') {
      walletName = dLayer.payButtonClickData
    } else {
      walletName = dLayer.payButtonClickData?.id
    }
  }

  let isWebView = false

  if (typeof window !== 'undefined' && !window.PaymentRequest && isMobile())
    isWebView = true

  const dataToReturn = {
    'wallet name': walletName || '',
    webview: isWebView ? 'In App webview' : 'Browser App',
    'Product ID': cartData.productIds,
    'Product Name': cartData.productNames,
    'SKU Code': cartData.productSkus,
    amount: amount,
    'Total Items': cartData.totalItems,
    'Coupon Applied': cartData.isCouponApplied,
    Coupon: cartData.coupon,
    'Rx Product': cartData.rxStatus,
    'Method Type Rank': parentIndex + 1,
    'Method Rank': childIndex + 1,
    'Method Type': paymentType,
    Method: paymentSubType,
    'Method partner': paymentMethod,
    'Shipping Charged': !cartData.isShippingFree,
    'Shipping Amount': cartData.shippingValue,
    'Discount Amount': cartData.discountValue,
    'Free Gift Items': cartData.freeGiftsCount,
    'Wallet Balance': cartData.walletPoints,
    'Wallet Used': cartData.isWalletApplied,
    URL: cartData.url,
    'Cart Type': cartData.cartType,
    'Sub Total': cartData.subTotal,
    'Payment type': 'Order',
  }

  return dataToReturn
}

export const processApplyDiscountClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {}

  return dataToReturn
}

export const processCartViewed = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {user} = dLayer || {}
  const {cart} = data || {}
  const cartData = getCartDetailsForAnalytics(cart, false, user)

  const dataToReturn = {
    'Product ID': cartData.productIds,
    'Product Name': cartData.productNames,
    'SKU Code': cartData.productSkus,
    'Total Items': cartData.totalItems,
    amount: cartData.totalAmount,
    'Coupon Applied': cartData.isCouponApplied,
    Coupon: cartData.coupon,
    Source: cartData.source,
    'Rx Product': cartData.rxStatus,
    'Shipping Charged': !cartData.isShippingFree,
    'Shipping Amount': cartData.shippingValue,
    'Free Gift Items': cartData.freeGiftsCount,
    'Wallet Used': cartData.isWalletApplied,
    'Wallet Balance': cartData.walletPoints,
    'Cart URL': cartData.url,
    'Cart Type': cartData.cartType,
    'Sub Total': cartData.subTotal,
    'Discount Amount': cartData.discountValue,
    'Cart Ui Type': cartData?.cartUi,
    ...(cartData?.milestoneTierValue
      ? {'Milestone Tier Value': cartData?.milestoneTierValue}
      : {}),
    ...cartData?.analyticsData,
    ...cartData?.flags,
  }
  return dataToReturn
}

export const processNacCartViewed = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {cart, user} = dLayer || {}
  const cartData = getCartDetailsForAnalytics(cart, false, user)
  const {rxStatus = false} = cartData || {}
  const {selectLanguage = null} = data || {}

  const dataToReturn = {
    'Product ID': cartData.productIds,
    'Product Name': cartData.productNames,
    'SKU Code': cartData.productSkus,
    'Total Items': cartData.totalItems,
    amount: cartData.totalAmount,
    'Coupon Applied': cartData.isCouponApplied,
    Coupon: cartData.coupon,
    Source: cartData.source,
    'Rx Product': cartData.rxStatus,
    'Shipping Charged': !cartData.isShippingFree,
    'Shipping Amount': cartData.shippingValue,
    'Free Gift Items': cartData.freeGiftsCount,
    'Wallet Used': cartData.isWalletApplied,
    'Wallet Balance': cartData.walletPoints,
    'Cart URL': cartData.url,
    'Cart Type': cartData.cartType,
    'Sub Total': cartData.subTotal,
    'Discount Amount': cartData.discountValue,
    'Default Language Available':
      rxStatus && selectLanguage ? !selectLanguage : undefined,
  }
  return dataToReturn
}

export const processChangeAddressClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const dataToReturn = {}

  return dataToReturn
}

export const processPromptPopupClick = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {propertyName = ''} = data || {}
  const dataToReturn = {
    optionChosen: propertyName,
  }
  return dataToReturn
}

export const processPromptPopupSeen = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {productID = ''} = data || {}
  const dataToReturn = {
    productID,
  }
  return dataToReturn
}

export const processRewardUnlocked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {mileStone = ''} = data || {}
  const dataToReturn = {
    mileStone,
  }
  return dataToReturn
}

export const processAddressIdSwitched = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {option = 0, source = ''} = data || {}
  const {gokwikAddresses} = dLayer || {}
  const {addressCount = 0, ifEmailExistsInAddress = false} =
    gokwikAddresses || {}
  const dataToReturn = {
    'Address Rank': option + 1,
    Source: source,
    'Gokwik Address available': addressCount > 0,
    'Count of Gokwik Addresses': addressCount,
    'Gokwik Email Exists': ifEmailExistsInAddress,
  }
  return dataToReturn
}

export const processUPIFailed = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  let isWebView = false

  if (typeof window !== 'undefined' && !window.PaymentRequest && isMobile())
    isWebView = true
  const {error} = data || {}
  const dataToReturn = {
    error: error,
    webview: isWebView ? 'In App webview' : 'Browser App',
  }
  return dataToReturn
}

export const processAppPresenceCheck = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {Google_Pay} = data || {}

  const dataToReturn = {
    Google_Pay: !!Google_Pay ? 'Y' : 'N',
  }

  return dataToReturn
}

export const processGoogleUPIFailed = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {error} = data || {}
  const dataToReturn = {
    error: error,
  }
}

export const processAppointmentCardShown = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    cardType = '',
    source = '',
    type = '',
    reason = '',
    rxProduct = true,
    rxExists = false,
    appointmentExists = false,
    isAvailable = false,
  } = data

  if (newCartEnabled) {
    return {'Card Type': cardType, Source: source}
  }

  const dataToReturn = {
    Type: type,
    Reason: reason,
    'Rx Product': rxProduct,
    'Rx Exists': rxExists,
    'Appointment Exists': appointmentExists,
    'IA Available': isAvailable,
    amount: dLayer.cart?.totalAmount || 0,
  }
  return dataToReturn
}

export const processPaymentViewed = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {cart, user, hideCod} = dLayer || {}
  const cartData = getCartDetailsForAnalytics(cart, hideCod, user)
  let isWebView = false
  if (typeof window !== 'undefined' && !window.PaymentRequest && isMobile())
    isWebView = true
  const dataToReturn = {
    'Product ID': cartData.productIds,
    'Product Name': cartData.productNames,
    'SKU Code': cartData.productSkus,
    'Total Items': cartData.totalItems,
    amount: cartData.totalAmount,
    'Coupon Applied': cartData.isCouponApplied,
    Coupon: cartData.coupon,
    'Rx Product': cartData.rxStatus,
    'Shipping Charged': !cartData.isShippingFree,
    'Shipping Amount': cartData.shippingValue,
    'Free Gift Items': cartData.freeGiftsCount,
    'Wallet Used': cartData.isWalletApplied,
    'Wallet Balance': cartData.walletPoints,
    URL: cartData.url,
    'Cart Type': cartData.cartType,
    webview: isWebView ? 'In App webview' : 'Browser App',
    'Sub Total': cartData.subTotal,
    'Discount Amount': cartData.discountValue,
    'Payment type': 'Order',
  }

  return dataToReturn
}

export const processWalletMoneyCheckbox = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {cart, user, hideCod} = dLayer || {}
  const cartData = getCartDetailsForAnalytics(cart, hideCod, user)
  let isWebView = false
  if (typeof window !== 'undefined' && !window.PaymentRequest && isMobile())
    isWebView = true
  const dataToReturn = {
    'Product ID': cartData.productIds,
    'Product Name': cartData.productNames,
    'SKU Code': cartData.productSkus,
    'Total Items': cartData.totalItems,
    amount: cartData.totalAmount,
    'Coupon Applied': cartData.isCouponApplied,
    Coupon: cartData.coupon,
    'Rx Product': cartData.rxStatus,
    'Shipping Charged': !cartData.isShippingFree,
    'Shipping Amount': cartData.shippingValue,
    'Free Gift Items': cartData.freeGiftsCount,
    'Wallet Used': cartData.isWalletApplied,
    'Wallet Balance': cartData.walletPoints,
    URL: cartData.url,
    'Cart Type': cartData.cartType,
    'COD Blocked': cartData.codBlocked,
    webview: isWebView ? 'In App webview' : 'Browser App',
    'Sub Total': cartData.subTotal,
    'Discount Amount': cartData.discountValue,
    'Payment type': 'Order',
  }

  return dataToReturn
}

export const processPaymentBannerClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {index = 0, text = ''} = data || {}
  const {cart, user, hideCod} = dLayer || {}
  const cartData = getCartDetailsForAnalytics(cart, hideCod, user)
  let isWebView = false
  if (typeof window !== 'undefined' && !window.PaymentRequest && isMobile())
    isWebView = true

  const dataToReturn = {
    'Product ID': cartData.productIds,
    'Product Name': cartData.productNames,
    'SKU Code': cartData.productSkus,
    'Total Items': cartData.totalItems,
    amount: cartData.totalAmount,
    'Coupon Applied': cartData.isCouponApplied,
    Coupon: cartData.coupon,
    'Rx Product': cartData.rxStatus,
    'Shipping Charged': !cartData.isShippingFree,
    'Shipping Amount': cartData.shippingValue,
    'Free Gift Items': cartData.freeGiftsCount,
    'Wallet Used': cartData.isWalletApplied,
    'Wallet Balance': cartData.walletPoints,
    URL: cartData.url,
    'Cart Type': cartData.cartType,
    'COD Blocked': cartData.codBlocked,
    webview: isWebView ? 'In App webview' : 'Browser App',
    'Sub Total': cartData.subTotal,
    'Discount Amount': cartData.discountValue,
    'Payment Offer Text': text,
    'Offer Rank': index + 1,
    'Payment type': 'Order',
  }
  return dataToReturn
}

export const processAddNewMethodCliked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {
    paymentMethod,
    paymentType,
    paymentSubType,
    currentParentSelected = 0,
  } = data || {}
  const {cart, user, hideCod} = dLayer || {}
  const cartData = getCartDetailsForAnalytics(cart, hideCod, user)
  let isWebView = false
  if (typeof window !== 'undefined' && !window.PaymentRequest && isMobile())
    isWebView = true
  const dataToReturn = {
    'Product ID': cartData.productIds,
    'Product Name': cartData.productNames,
    'SKU Code': cartData.productSkus,
    'Total Items': cartData.totalItems,
    amount: cartData.totalAmount,
    'Coupon Applied': cartData.isCouponApplied,
    Coupon: cartData.coupon,
    'Rx Product': cartData.rxStatus,
    'Shipping Charged': !cartData.isShippingFree,
    'Shipping Amount': cartData.shippingValue,
    'Free Gift Items': cartData.freeGiftsCount,
    'Wallet Used': cartData.isWalletApplied,
    'Wallet Balance': cartData.walletPoints,
    URL: cartData.url,
    'Cart Type': cartData.cartType,
    'COD Blocked': cartData.codBlocked,
    webview: isWebView ? 'In App webview' : 'Browser App',
    'Sub Total': cartData.subTotal,
    'Discount Amount': cartData.discountValue,
    'Method Type': paymentType,
    Method: paymentSubType,
    'Method partner': paymentMethod,
    'Method Type Rank': currentParentSelected + 1,
    'Method Rank': 0,
    'Payment type': 'Order',
  }
  return dataToReturn
}

export const processPaymentEligibility = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  return data
}

export const processAppointmentCardClick = (data: any) => {
  const {buttonLabel = '', referenceNo = '', source = '', label = ''} = data

  return {
    'Button Label': buttonLabel,
    Label: label,
    'Reference Number': referenceNo,
    Source: source,
  }
}

export const processWalletCalloutSeen = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {cart, user, hideCod} = dLayer || {}
  const cartData = getCartDetailsForAnalytics(cart, hideCod, user)
  const {isLoggedIn = false, repeatUser = false} = user
  const dataToReturn = {
    'Discount Amount': cartData.discountValue,
    'Free Gift Items': cartData.freeGiftsCount,
    isLoggedIn,
    'Shipping Charged': !cartData.isShippingFree,
    'Sub Total': cartData.subTotal,
    'Total Items': cartData.totalItems,
    'Wallet Used': cartData.isWalletApplied,
    'Wallet Balance': cartData.walletPoints,
    amount: cartData.totalAmount,
    'User Type': repeatUser ? 'Repeat' : 'New',
    'Product Name': cartData.productNames,
  }

  return dataToReturn
}

export const processWalletCalloutClicked = (
  data: any,
  dLayer: typeof window.customDataLayer
) => {
  const {amount: rechargeAmount = 0} = data
  const {cart, user, hideCod} = dLayer || {}
  const cartData = getCartDetailsForAnalytics(cart, hideCod, user)
  const {isLoggedIn = false, repeatUser = false} = user
  const dataToReturn = {
    'Discount Amount': cartData.discountValue,
    'Free Gift Items': cartData.freeGiftsCount,
    isLoggedIn,
    'Shipping Charged': !cartData.isShippingFree,
    'Sub Total': cartData.subTotal,
    'Total Items': cartData.totalItems,
    'Wallet Used': cartData.isWalletApplied,
    'Wallet Balance': cartData.walletPoints,
    amount: cartData.totalAmount,
    'User Type': repeatUser ? 'Repeat' : 'New',
    'Product Name': cartData.productNames,
    'Wallet Recharge Amount': rechargeAmount,
  }

  return dataToReturn
}
