import {HttpClient} from '@mosaic-wellness/redux-action-library'
import {useCallback, useState} from 'react'

const GET_ALL_COUPONS = 'offers/cart'

export const useGetAvailableCoupons = ({showWalletCouponsOnTop = true}) => {
  const [couponDataRes, setCouponDataRes] = useState({})
  const axiosInstance = HttpClient.getAxiosInstance()
  const getData = useCallback(
    async function getData() {
      try {
        const response = await axiosInstance.get(GET_ALL_COUPONS, {
          params: {showWalletCouponsOnTop},
        })
        const {data} = response?.data || {}
        if (data) {
          setCouponDataRes(data)
        }
      } catch (error) {
        setCouponDataRes({})
      }
    },
    [axiosInstance, showWalletCouponsOnTop]
  )

  return {couponDataRes, getData}
}
