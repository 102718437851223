import React, {useCallback, useState} from 'react'
import {AccordionItemContainer} from './AccordionItem.styles'
import {IAccordionItemProps} from './AccordionItem.interface'
import {
  ChevronDown,
  ChevronUp,
  Typography,
  TypographyVariants,
} from '@web-components'

function AccordionItem(props: IAccordionItemProps) {
  const {
    content = '',
    title = '',
    initiallyOpen = false,
    customContainerClassName = '',
    titleVariant = TypographyVariants.BODY_BASE_BOLD,
    children,
  } = props || {}

  const [isOpen, setIsOpen] = useState(initiallyOpen)

  const handleAccordionClick = useCallback(() => {
    setIsOpen((flag) => !flag)
  }, [])

  if (!title) {
    return null
  }

  return (
    <AccordionItemContainer
      className={`accordion-item ${customContainerClassName}`}
      data-isopen={isOpen}
    >
      <div onClick={handleAccordionClick}>
        <div className="title-container">
          <Typography variant={titleVariant} customClassName="title-text">
            {title}
          </Typography>
          <div className="chevron-icon">
            {isOpen ? <ChevronUp /> : <ChevronDown />}
          </div>
        </div>

        {content && (
          <Typography
            variant={TypographyVariants.BODY_BASE_REGULAR}
            customClassName="content-text"
          >
            {content}
          </Typography>
        )}
        {children && isOpen && <>{children}</>}
      </div>
    </AccordionItemContainer>
  )
}

export default AccordionItem
