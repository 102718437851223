import {RatingStar, Typography, TypographyVariants} from '@web-components'
import {IRatingsSummaryProps} from './RatingsSummary.interface'
import {RatingsSummaryContainer} from './RatingsSummary.styles'

function RatingsSummary(props: IRatingsSummaryProps) {
  const {ratingOverview} = props
  const {overAllRating, ratingsStats, totalNumberOfReviews} =
    ratingOverview || {}

  return (
    <RatingsSummaryContainer>
      <div className="overall-rating-container">
        <div className="overall-rating">
          <Typography
            variant={TypographyVariants.HEADING_LARGE_BOLD}
            customClassName="overall-rating-text"
          >
            {overAllRating}
          </Typography>
          <RatingStar height={24} width={24} stroke="none" />
        </div>
        <Typography
          variant={TypographyVariants.TAG_REGULAR}
          customClassName="total-reviews-text"
        >
          {totalNumberOfReviews} Reviews
        </Typography>
      </div>
      <div className="rating-stats-container">
        {ratingsStats?.map((ratingStat) => {
          const {
            fillerColor = '',
            numberOfReviews = 0,
            rating = 0,
            numberOfReviewsLabel = '',
          } = ratingStat || {}

          const figure = Math.ceil(
            (numberOfReviews / totalNumberOfReviews) * 100
          )

          return (
            <div
              className="rating-bar-container"
              key={ratingStat.numberOfReviews}
            >
              <div className="rating-number-container">
                <Typography variant={TypographyVariants.TAG_REGULAR}>
                  {rating}
                </Typography>
                <div className="star">
                  <RatingStar width={12} height={12} stroke="none" />
                </div>
              </div>
              <div className="strip">
                <div
                  className="filled-strip"
                  style={{background: fillerColor, width: figure}}
                />
              </div>
              <div className="trailing-text-container">
                <Typography
                  variant={TypographyVariants.TAG_REGULAR}
                  customClassName="trailing-text"
                >
                  {numberOfReviewsLabel}
                </Typography>
              </div>
            </div>
          )
        })}
      </div>
    </RatingsSummaryContainer>
  )
}

export default RatingsSummary
