import styled from 'styled-components'

export const TopBarContainer = styled.div`
  max-width: 1120px;
  background-color: white;
  width: 100%;
  margin: auto;

  .top-bar-data {
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .top-bar-button {
    border: 0;
    outline: 0;
    font-family: var(--brand-font-family-text);
    padding: 6px 10px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: var(--brand-font-weight-medium);
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .back-button {
    gap: 5px;
    color: var(--category-primary-color);
    background-color: var(--category-primary-bg1-color);

    .chevron-icon {
      margin-right: 6px;
    }
  }

  .close-button {
    margin-left: auto;
    color: var(--brand-primary-red-color);
    background-color: var(--brand-primary-red-bg1-color);
  }

  @media screen and (min-width: 768px) {
    .top-bar-data {
      padding: 30px;
    }

    .dummy-placeholder {
      width: 36px;
      margin-right: auto;
    }

    .top-bar-button {
      padding: 10px 12px;
      font-size: 16px;
    }

    .question-text {
      font-size: 28px;
      margin: 0 0 40px 0;
    }

    .appointment-details-top-sheet {
      margin: auto;
    }
  }
`
