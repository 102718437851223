export const CLIENT_ENV = {
  NEXT_PUBLIC_BRAND: process.env.NEXT_PUBLIC_BRAND,
  NEXT_PUBLIC_GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
  NEXT_PUBLIC_GA_ID: process.env.NEXT_PUBLIC_GA_ID,
  NEXT_PUBLIC_CLEVER_TAP_ID: process.env.NEXT_PUBLIC_CLEVER_TAP_ID,
  NEXT_PUBLIC_GO_KWIK_ENV: process.env.NEXT_PUBLIC_GO_KWIK_ENV,
  NEXT_PUBLIC_CALLBACK_URL_FOR_RAZORPAY:
    process.env.NEXT_PUBLIC_CALLBACK_URL_FOR_RAZORPAY,
  NEXT_PUBLIC_BACKEND: process.env.NEXT_PUBLIC_BACKEND ?? '',
  NEXT_PUBLIC_BACKEND_ORIGIN: process.env.NEXT_PUBLIC_BACKEND_ORIGIN ?? '',
  NEXT_PUBLIC_RAZORYPAY_KEY: process.env.NEXT_PUBLIC_RAZORYPAY_KEY ?? '',
  NEXT_PUBLIC_RUM_AGENT: process.env.NEXT_PUBLIC_RUM_AGENT,
  NEXT_PUBLIC_FB_PIXEL_ID: process.env.NEXT_PUBLIC_FB_PIXEL_ID,
  NEXT_PUBLIC_SNAP_PIXEL_ID: process.env.NEXT_PUBLIC_SNAP_PIXEL_ID,
  NEXT_PUBLIC_STAMPED_API_KEY_PUBLIC:
    process.env.NEXT_PUBLIC_STAMPED_API_KEY_PUBLIC,
  NEXT_PUBLIC_STAMPED_STORE_URL: process.env.NEXT_PUBLIC_STAMPED_STORE_URL,
  NEXT_PUBLIC_JUSPAY_MERCHANT_ID: process.env.NEXT_PUBLIC_JUSPAY_MERCHANT_ID,
  NEXT_PUBLIC_WHATSAPP_NUMBER: process.env.NEXT_PUBLIC_WHATSAPP_NUMBER,
  NEXT_PUBLIC_MIX_PANEL_TOKEN: process.env.NEXT_PUBLIC_MIX_PANEL_TOKEN,
  NEXT_PUBLIC_ALGOLIA_API_KEY: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
  NEXT_PUBLIC_ALGOLIA_APP_ID: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  NEXT_PUBLIC_ALGOLIA_INDEX_NAME: process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME,
  NEXT_PUBLIC_TRUECALLER_LOGIN_PARTNER_KEY:
    process.env.NEXT_PUBLIC_TRUECALLER_LOGIN_PARTNER_KEY,
  NEXT_PUBLIC_GA_ADS_CONVERSION_ID:
    process.env.NEXT_PUBLIC_GA_ADS_CONVERSION_ID,
  NEXT_PUBLIC_GA_ADS_CONVERSION_LABEL:
    process.env.NEXT_PUBLIC_GA_ADS_CONVERSION_LABEL,
  NEXT_PUBLIC_CUSTOM_ANALYTICS: process.env.NEXT_PUBLIC_CUSTOM_ANALYTICS,
  NEXT_PUBLIC_TWITTER_CONFIG: process.env.NEXT_PUBLIC_TWITTER_CONFIG,
  NEXT_PUBLIC_MOENGAGE_APP_ID: process.env.NEXT_PUBLIC_MOENGAGE_APP_ID,
  NEXT_PUBLIC_MOENGAGE_DEBUG_LOGS: process.env.NEXT_PUBLIC_MOENGAGE_DEBUG_LOGS,
  NEXT_PUBLIC_GA4_MEASUREMENT_ID: process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID,
  NEXT_PUBLIC_JUSPAY_URL: process.env.NEXT_PUBLIC_JUSPAY_URL,
  NEXT_PUBLIC_REPORT_VERSION: process.env.NEXT_PUBLIC_REPORT_VERSION,
  NEXT_PUBLIC_COMET_CHAT_APP_ID: process.env.NEXT_PUBLIC_COMET_CHAT_APP_ID,
  NEXT_PUBLIC_COMET_CHAT_REGION: process.env.NEXT_PUBLIC_COMET_CHAT_REGION,
  NEXT_PUBLIC_COMET_CHAT_AUTH_KEY: process.env.NEXT_PUBLIC_COMET_CHAT_AUTH_KEY,
  APP_NAME: process.env.APP_NAME,
  IS_DEV: process.env.NODE_ENV,
  BRAND: process.env.BRAND, //
  ENABLE_SOURCE_MAPS: process.env.ENABLE_SOURCE_MAPS,
  ANALYZE: process.env.ANALYZE,
  ASSET_PREFIX: process.env.ASSET_PREFIX, //
  STATIC_COMPRESSION: process.env.STATIC_COMPRESSION,
  NEXT_PUBLIC_COUNTRY: process.env.NEXT_PUBLIC_COUNTRY,
  FONT_ASSET_PREFIX: process.env.FONT_ASSET_PREFIX,
  NEXT_PUBLIC_GA_ADS_CONVERSION_ID_NEW:
    process.env.NEXT_PUBLIC_GA_ADS_CONVERSION_ID_NEW,
  NEXT_PUBLIC_GA_ADS_PURCHASE_CONVERSION_LABEL:
    process.env.NEXT_PUBLIC_GA_ADS_PURCHASE_CONVERSION_LABEL,
  NEXT_PUBLIC_GA_ADS_ATC_CONVERSION_LABEL:
    process.env.NEXT_PUBLIC_GA_ADS_ATC_CONVERSION_LABEL,
  NEXT_PUBLIC_GA_ADS_PDP_VIEWED_CONVERSION_LABEL:
    process.env.NEXT_PUBLIC_GA_ADS_PDP_VIEWED_CONVERSION_LABEL,
  NEXT_PUBLIC_GS_ID: process.env.NEXT_PUBLIC_GS_ID,
  NEXT_PUBLIC_ENABLE_NEW_HOME: process.env.NEXT_PUBLIC_ENABLE_NEW_HOME,
  NEXT_PUBLIC_EXTERNAL_NUDGE_API_KEY:
    process.env.NEXT_PUBLIC_EXTERNAL_NUDGE_API_KEY,
  NEXT_PUBLIC_ENABLE_NEW_CART: process.env.NEXT_PUBLIC_ENABLE_NEW_CART,
  NEXT_PUBLIC_STRIPE_PUBLIC_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY,
  NEXT_PUBLIC_TABBY_PUBLIC_KEY: process.env.NEXT_PUBLIC_TABBY_PUBLIC_KEY || '',
  SERVER_LOG_FILE_NAME: process.env.SERVER_LOG_FILE_NAME || '',
  SCREEN_WIDTH: process.env.SCREEN_WIDTH,
  NEXT_PUBLIC_BW_MAGENTO: process.env.NEXT_PUBLIC_BW_MAGENTO,
  NEXT_PUBLIC_FB_ID: process.env.NEXT_PUBLIC_FB_ID || '',
  NEXT_PUBLIC_WISHLINK_ID: process.env.NEXT_PUBLIC_WISHLINK_ID,
  NEXT_PUBLIC_ENABLE_NEW_PDP: process.env.NEXT_PUBLIC_ENABLE_NEW_PDP,
  NEXT_PUBLIC_DEEPLINK_PREFIX: process.env.NEXT_PUBLIC_DEEPLINK_PREFIX,
  NEXT_PUBLIC_CURRENCY_CODE: process.env.NEXT_PUBLIC_CURRENCY_CODE,
}
