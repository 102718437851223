import {useRouter} from 'next/router'
import React, {memo, useEffect, useMemo, useState} from 'react'
import {StyledLoading} from './ProductPageLoading/PageLoading.styles'
import PDPSkeleton from './ProductPageLoading/PDPSkeleton'
import HomeSkeleton from './HomePageLoading/HomeSkeleton'
import ShopSkeleton from './ShopPageLoading/ShopSkeleton'
import SubCategorySkeleton from './SubCategoryPageLoading/SubCategorySkeleton'
import GenericSkeleton from './GenericPageLoading/GenericSkeleton'
import ReviewsSkeleton from './AllReviewsPageLoading/ReviewsSkeleton'
import AllReviewsTopSkeleton from './AllReviewsPageLoading/AllReviewsTopSkeleton'
import QuestionsSkeleton from './AllQuestionsPageLoading/QuestionsSkeleton'
import AllQuestionsTopSkeleton from './AllQuestionsPageLoading/AllQuestionsTopSkeleton'
const PAGE_TYPES_REGEX = {
  PRODUCT: /\/(dp|product)\//,
  HOME: /^\/$/,
  SUBCATEGORY: /\/concern\//,
  SHOP: /\/(all-products|allproducts)/,
  CHECKOUT: /checkout-v2/,
  BYOK: /\/catalog\/build-your-own-kit/,
  BYOK_ONLY_WEB: /\/build-your-own-kit/,
}

const PAGE_TYPES = {
  PRODUCT: 'PRODUCT',
  HOME: 'HOME',
  SUBCATEGORY: 'SUBCATEGORY',
  SHOP: 'SHOP',
  CHECKOUT: 'CHECKOUT',
  GENERIC: 'GENERIC',
  REVIEWS: 'REVIEWS',
  ALL_REVIEWS: 'ALL REVIEWS',
  QUESTIONS: 'QUESTIONS',
  ALL_QUESTIONS: 'ALL QUESTIONS',
  BYOK: 'BYOK',
}

const findPageType = (url: string) => {
  const navPath = url.split('?')[0]

  switch (true) {
    case PAGE_TYPES_REGEX.CHECKOUT.test(url):
      return PAGE_TYPES.CHECKOUT

    case PAGE_TYPES_REGEX.HOME.test(navPath):
      return PAGE_TYPES.HOME

    case PAGE_TYPES_REGEX.PRODUCT.test(url):
      return PAGE_TYPES.PRODUCT

    case PAGE_TYPES_REGEX.SHOP.test(url):
      return PAGE_TYPES.SHOP
    case PAGE_TYPES_REGEX.SUBCATEGORY.test(url):
      return PAGE_TYPES.SUBCATEGORY
    case PAGE_TYPES_REGEX.BYOK.test(url):
    case PAGE_TYPES_REGEX.BYOK_ONLY_WEB.test(url):
      return PAGE_TYPES.BYOK

    default:
      return PAGE_TYPES.GENERIC
  }
}

const APP_SHELL_MAP = {
  [PAGE_TYPES.PRODUCT]: PDPSkeleton,
  //[PAGE_TYPES.CHECKOUT]: CheckoutSkelton, // commenting this because of it causing the multiple shimmer issue gets shown on cart page.
  [PAGE_TYPES.HOME]: HomeSkeleton,
  [PAGE_TYPES.SHOP]: ShopSkeleton,
  [PAGE_TYPES.SUBCATEGORY]: SubCategorySkeleton,
  [PAGE_TYPES.GENERIC]: GenericSkeleton,
  [PAGE_TYPES.REVIEWS]: ReviewsSkeleton,
  [PAGE_TYPES.ALL_REVIEWS]: AllReviewsTopSkeleton,
  [PAGE_TYPES.QUESTIONS]: QuestionsSkeleton,
  [PAGE_TYPES.ALL_QUESTIONS]: AllQuestionsTopSkeleton,
}

function PageLoading({children}) {
  const [nextPage, setNextPage] = useState('')
  const router = useRouter()

  useEffect(() => {
    const handleRouteChangeStart = (url: string) => {
      window.scrollTo({
        top: 0,
      })
      const pageType = findPageType(url)
      setNextPage(pageType)
    }
    const handleRouteChangeEnd = (url: string) => {
      setNextPage('')
    }
    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeEnd)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeEnd)
    }
  }, [router.events])
  const ComponentToRender = useMemo(() => {
    return APP_SHELL_MAP[nextPage]
  }, [nextPage])
  if (!nextPage) {
    return <>{children}</>
  }

  return (
    <StyledLoading>{ComponentToRender && <ComponentToRender />}</StyledLoading>
  )
}

export default memo(PageLoading)
