import {Accordion} from './Accordion'
import {BannerWidget} from './Banner'
import {CalloutWithImage} from './CalloutWithImage'
import {CtaButton} from './CtaButton'
import {MenuRowItemWidget} from './MenuRowItem'
import {CustomComponentWidget} from './CustomWidget'
import {HeroBannerWidget} from './HeroBanner'
import {ImageCarouselWidget} from './ImageCarousel'
import {ImageSliderWidget} from './ImageSlider'
import {InformationGridStrip} from './InformationGridStrip'
import {MediaWithFooterSlider} from './MediaWithFooterSlider'
import {MediaWithHeaderSlider} from './MediaWithHeaderSlider'
import {SocialReviewList} from './SocialReviewList'
import {RxPreviewWidget} from './RxPreview'
import {RxConsultDetailsWidget} from './RxConsultDetails'
import {TestimonialsWidget} from './Testimonials'
import {TrustMarkersWidget} from './TrustMarkers'
import {VideoSliderWidget} from './VideoSlider'
import {IconGrid} from './IconGrid'
import {IconWithLabelSlider} from './IconWithLabelSlider'
import {ImageGrid} from './ImageGrid'
import {ComparisonTable} from './ComparisonTable'
import {ProductSwitch} from './ProductSwitch'
import {DownloadAppCallout} from './DownloadAppCallout'
import {ProductsGrid} from './ProductsGrid'
import {VideoImageProductCardSliderWidget} from './VideoImageProductCardSlider'
import {ProductCardV2Slider} from './ProductCardV2Slider'
import {CategoryTagsProductCardsV2Grid} from './CategoryTagsProductCardsV2Grid'
import {ProductSummary} from './ProductSummary'
import {RatingsAndReviews} from './RatingsAndReviews'
import {StickyCtaFooter} from './StickyCtaFooter'
import {KitBreakdown} from './KitBreakdown'
import {MiniAssessment} from './MiniAssessment'
import {CategoryProductCardGrid} from './CategoryProductCardGrid'
import {MediaSlider} from './MediaSlider'
import ProductListing from './ProductLisiting/ProductListing'
import CategoryProductCardCarousel from './CategoryProductCardCarousel/CategoryProductCardCarousel'
import SearchInputWithRecommendationWrapper from './SearchInputWithRecommendation/SearchInputWithRecommendationWrapper'
import {TilesWidget} from './Tiles'
import StickyCartSummary from './StickyCartSummary'
import {InfoCardsSliderWidget} from './InfoCardsSlider'
import {MobileCategoryPalette} from './MobileCategoryPalette'
import {DesktopCategoryPalette} from './DesktopCategoryPalette'
import {ImageWithDescriptionSlider} from './ImageWithDescriptionSlider'
import {TestimonialProductCardCarousel} from './TestimonialProductCardCarousel'
import {DoctorListingCard} from './DoctorListingCard'
import {DoctorAppointmentCard} from './DoctorAppointmentCard'
import {HybridLayoutWidget} from './HybridLayout'
import {DoctorListingHeading} from './DoctorListingHeading'
import {DoctorListingGrid} from './DoctorListingGrid'
import {DoctorListingHtmlText} from './DoctorListingHtmlText'
import {DoctorListingCarousel} from './DoctorListingCarousel'
import {DoctorListingCredBanner} from './DoctorListingCredBanner'
import ProductCardGrid from './ProductCardGrid/ProductCardGrid'
import {FeedbackBannerWidget} from './FeedbackBanner'
import {OrdersListingInSupportWidget} from './OrdersListingInSupport'
import {SupportTicketsListingWidget} from './SupportTicketsListing'
import {SupportMenuItemsWidget} from './SupportMenuItems'

export const BASE_WIDGET_MAP_TYPES = {
  BANNER: 'BANNER',
  MOBILE_BANNER: 'MOBILE_BANNER',
  DESKTOP_BANNER: 'DESKTOP_BANNER',
  CUSTOM_COMPONENT: 'CUSTOM_COMPONENT',
  HERO_BANNER: 'HERO_BANNER',
  TESTIMONIALS: 'TESTIMONIALS',
  TRUST_MARKERS: 'TRUST_MARKERS',
  // NEW_WIDGETS
  IMAGE_GALLERY: 'IMAGE_GALLERY',
  PRODUCT_DETAILS: 'PRODUCT_DETAILS',
  PDP_VARIANTS: 'PDP_VARIANTS',
  TABBED_CONTENT: 'TABBED_CONTENT',
  CHECK_DELIVERY: 'CHECK_DELIVERY',
  MEDIA_WITH_CTA_GRID: 'MEDIA_WITH_CTA_GRID',
  MEDIA_DESCRIPTION_SLIDER: 'MEDIA_DESCRIPTION_SLIDER',
  PRODUCT_CONTENTS: 'PRODUCT_CONTENTS',
  STORIES: 'STORIES',
  ACCORDION: 'ACCORDION',
  ICON_CARD_GRID: 'ICON_CARD_GRID',
  RATINGS_BREAKDOWN: 'RATINGS_BREAKDOWN',
  REVIEW_LISTING: 'REVIEW_LISTING',
  QNA_LISTING: 'QNA_LISTING',
  ICON_CARD_WITH_INFO_LIST: 'ICON_CARD_WITH_INFO_LIST',
  COLLAPSIBLE_TEXT_SECTION: 'COLLAPSIBLE_TEXT_SECTION',
  BREAD_CRUMB: 'BREAD_CRUMB',
  MAT_PALLET: 'MAT_PALLET',
  TABBED_PRODUCT_LISTING: 'TABBED_PRODUCT_LISTING',
  STEP_SUMMARY: 'STEP_SUMMARY', //How it works on MM Home
  PRODUCT_TESTIMONIALS: 'PRODUCT_TESTIMONIALS',
  BLOG_CARD_SLIDER: 'BLOG_CARD_SLIDER',
  BOOSTS: 'BOOSTS',
  PRODUCT_SWITCHER: 'PRODUCT_SWITCHER',
  MEDIA_ROW: 'MEDIA_ROW',
  MEDIA_WITH_HEADER_SLIDER: 'MEDIA_WITH_HEADER_SLIDER',
  MEDIA_WITH_FOOTER_SLIDER: 'MEDIA_WITH_FOOTER_SLIDER',
  IMAGE_SLIDER: 'IMAGE_SLIDER',
  TABBED_ACCORDION: 'TABBED_ACCORDION',
  INFORMATION_GRID_STRIP: 'INFORMATION_GRID_STRIP',
  CALLOUT_WITH_IMAGE: 'CALLOUT_WITH_IMAGE',
  VIDEO_SLIDER: 'VIDEO_SLIDER',
  IMAGE_CAROUSEL: 'IMAGE_CAROUSEL',
  CTA_BUTTON: 'CTA_BUTTON',
  SOCIAL_REVIEW_LIST: 'SOCIAL_REVIEW_LIST',
  RX_CONSULT_DETAILS: 'RX_CONSULT_DETAILS',
  MENU_ROW_ITEM: 'MENU_ROW_ITEM',
  RX_PREVIEW: 'RX_PREVIEW',
  ICON_GRID: 'ICON_GRID',
  ICON_WITH_LABEL_SLIDER: 'ICON_WITH_LABEL_SLIDER',
  IMAGE_GRID: 'IMAGE_GRID',
  COMPARISON_TABLE: 'COMPARISON_TABLE',
  PRODUCT_SWITCH: 'PRODUCT_SWITCH',
  DOWNLOAD_APP_CALLOUT: 'DOWNLOAD_APP_CALLOUT',
  PRODUCTS_GRID: 'PRODUCTS_GRID',
  VIDEO_IMAGE_PRODUCT_CARD_SLIDER: 'VIDEO_IMAGE_PRODUCT_CARD_SLIDER',
  PRODUCT_CARD_V2_SLIDER: 'PRODUCT_CARD_V2_SLIDER',
  CATEGORY_PRODUCT_CARD_GRID: 'CATEGORY_PRODUCT_CARD_GRID',
  CATEGORY_TAGS_PRODUCT_CARD_V2_GRID: 'CATEGORY_TAGS_PRODUCT_CARD_V2_GRID',
  PRODUCT_SUMMARY: 'PRODUCT_SUMMARY',
  RATINGS_AND_REVIEWS: 'RATINGS_AND_REVIEWS',
  STICKY_CTA_FOOTER: 'STICKY_CTA_FOOTER',
  KIT_BREAKDOWN: 'KIT_BREAKDOWN',
  MINI_ASSESSMENT: 'MINI_ASSESSMENT',
  CLICKABLE_IMAGE_SLIDER: 'CLICKABLE_IMAGE_SLIDER',
  TILES: 'TILES',
  DESKTOP_TILES: 'DESKTOP_TILES',
  MOBILE_TILES: 'MOBILE_TILES',
  DESKTOP_IMAGE_SLIDER: 'DESKTOP_IMAGE_SLIDER',
  MOBILE_IMAGE_SLIDER: 'MOBILE_IMAGE_SLIDER',
  PRODUCT_LISTING: 'PRODUCT_LISTING',
  CATEGORY_PRODUCT_CARD_CAROUSEL: 'CATEGORY_PRODUCT_CARD_CAROUSEL',
  SEARCH_INPUT_WITH_RECOMMENDATION: 'SEARCH_INPUT_WITH_RECOMMENDATION',
  STICKY_CART_SUMMARY: 'STICKY_CART_SUMMARY',
  INFO_CARD_SLIDER: 'INFO_CARD_SLIDER',
  MOBILE_CATEGORY_PALETTE: 'MOBILE_CATEGORY_PALETTE',
  DESKTOP_CATEGORY_PALETTE: 'DESKTOP_CATEGORY_PALETTE',
  TESTIMONIAL_PRODUCT_CARDS: 'TESTIMONIAL_PRODUCT_CARDS',
  TESTIMONIAL_PRODUCT_CARD_CAROUSEL: 'TESTIMONIAL_PRODUCT_CARD_CAROUSEL',
  IMAGE_WITH_DESCRIPTION_SLIDER: 'IMAGE_WITH_DESCRIPTION_SLIDER',
  DOCTOR_LISTING_CARD: 'DOCTOR_LISTING_CARD',
  DOCTOR_APPOINTMENT_CARD: 'DOCTOR_APPOINTMENT_CARD',
  HYBRID_LAYOUT: 'HYBRID_LAYOUT',
  DOCTOR_LISTING_HEADING: 'DOCTOR_LISTING_HEADING',
  DOCTOR_LISTING_CAROUSEL: 'DOCTOR_LISTING_CAROUSEL',
  DOCTOR_LISTING_HTML_TEXT: 'DOCTOR_LISTING_HTML_TEXT',
  DOCTOR_LISTING_GRID: 'DOCTOR_LISTING_GRID',
  DOCTOR_LISTING_CRED_BANNER: 'DOCTOR_LISTING_CRED_BANNER',
  MOBILE_PRODUCT_CARD_GRID: 'MOBILE_PRODUCT_CARD_GRID',
  DESKTOP_PRODUCT_CARD_GRID: 'DESKTOP_PRODUCT_CARD_GRID',
  MOBILE_CATEGORY_PRODUCT_CARD_CAROUSEL:
    'MOBILE_CATEGORY_PRODUCT_CARD_CAROUSEL',
  DESKTOP_CATEGORY_PRODUCT_CARD_CAROUSEL:
    'DESKTOP_CATEGORY_PRODUCT_CARD_CAROUSEL',
  FEEDBACK_BANNER: 'FEEDBACK_BANNER',
  ORDERS_LISTING_IN_SUPPORT: 'ORDERS_LISTING_IN_SUPPORT',
  SUPPORT_TICKETS_LISTING: 'SUPPORT_TICKETS_LISTING',
  SUPPORT_MENU_ITEMS: 'SUPPORT_MENU_ITEMS',
}

export type WidgetType =
  (typeof BASE_WIDGET_MAP_TYPES)[keyof typeof BASE_WIDGET_MAP_TYPES]
export const BASE_WIDGET_MAP: Record<WidgetType, React.ComponentType<any>> = {
  [BASE_WIDGET_MAP_TYPES.BANNER]: BannerWidget,
  [BASE_WIDGET_MAP_TYPES.MOBILE_BANNER]: BannerWidget,
  [BASE_WIDGET_MAP_TYPES.DESKTOP_BANNER]: BannerWidget,
  [BASE_WIDGET_MAP_TYPES.CUSTOM_COMPONENT]: CustomComponentWidget,
  [BASE_WIDGET_MAP_TYPES.HERO_BANNER]: HeroBannerWidget,
  [BASE_WIDGET_MAP_TYPES.IMAGE_SLIDER]: ImageSliderWidget,
  [BASE_WIDGET_MAP_TYPES.CLICKABLE_IMAGE_SLIDER]: MediaSlider,
  [BASE_WIDGET_MAP_TYPES.TESTIMONIALS]: TestimonialsWidget,
  [BASE_WIDGET_MAP_TYPES.TRUST_MARKERS]: TrustMarkersWidget,
  [BASE_WIDGET_MAP_TYPES.VIDEO_SLIDER]: VideoSliderWidget,
  [BASE_WIDGET_MAP_TYPES.MEDIA_WITH_HEADER_SLIDER]: MediaWithHeaderSlider,
  [BASE_WIDGET_MAP_TYPES.MEDIA_WITH_FOOTER_SLIDER]: MediaWithFooterSlider,
  [BASE_WIDGET_MAP_TYPES.IMAGE_CAROUSEL]: ImageCarouselWidget,
  [BASE_WIDGET_MAP_TYPES.ACCORDION]: Accordion,
  [BASE_WIDGET_MAP_TYPES.INFORMATION_GRID_STRIP]: InformationGridStrip,
  [BASE_WIDGET_MAP_TYPES.CALLOUT_WITH_IMAGE]: CalloutWithImage,
  [BASE_WIDGET_MAP_TYPES.CTA_BUTTON]: CtaButton,
  [BASE_WIDGET_MAP_TYPES.SOCIAL_REVIEW_LIST]: SocialReviewList,
  [BASE_WIDGET_MAP_TYPES.RX_CONSULT_DETAILS]: RxConsultDetailsWidget,
  [BASE_WIDGET_MAP_TYPES.MENU_ROW_ITEM]: MenuRowItemWidget,
  [BASE_WIDGET_MAP_TYPES.RX_PREVIEW]: RxPreviewWidget,
  [BASE_WIDGET_MAP_TYPES.ICON_GRID]: IconGrid,
  [BASE_WIDGET_MAP_TYPES.ICON_WITH_LABEL_SLIDER]: IconWithLabelSlider,
  [BASE_WIDGET_MAP_TYPES.IMAGE_GRID]: ImageGrid,
  [BASE_WIDGET_MAP_TYPES.COMPARISON_TABLE]: ComparisonTable,
  [BASE_WIDGET_MAP_TYPES.PRODUCT_SWITCH]: ProductSwitch,
  [BASE_WIDGET_MAP_TYPES.DOWNLOAD_APP_CALLOUT]: DownloadAppCallout,
  [BASE_WIDGET_MAP_TYPES.PRODUCTS_GRID]: ProductsGrid,
  [BASE_WIDGET_MAP_TYPES.VIDEO_IMAGE_PRODUCT_CARD_SLIDER]:
    VideoImageProductCardSliderWidget,
  [BASE_WIDGET_MAP_TYPES.PRODUCT_CARD_V2_SLIDER]: ProductCardV2Slider,
  [BASE_WIDGET_MAP_TYPES.CATEGORY_PRODUCT_CARD_GRID]: CategoryProductCardGrid,
  [BASE_WIDGET_MAP_TYPES.CATEGORY_TAGS_PRODUCT_CARD_V2_GRID]:
    CategoryTagsProductCardsV2Grid,
  [BASE_WIDGET_MAP_TYPES.PRODUCT_SUMMARY]: ProductSummary,
  [BASE_WIDGET_MAP_TYPES.RATINGS_AND_REVIEWS]: RatingsAndReviews,
  [BASE_WIDGET_MAP_TYPES.STICKY_CTA_FOOTER]: StickyCtaFooter,
  [BASE_WIDGET_MAP_TYPES.KIT_BREAKDOWN]: KitBreakdown,
  [BASE_WIDGET_MAP_TYPES.MINI_ASSESSMENT]: MiniAssessment,
  [BASE_WIDGET_MAP_TYPES.TILES]: TilesWidget,
  [BASE_WIDGET_MAP_TYPES.DESKTOP_TILES]: TilesWidget,
  [BASE_WIDGET_MAP_TYPES.MOBILE_TILES]: TilesWidget,
  [BASE_WIDGET_MAP_TYPES.INFO_CARD_SLIDER]: InfoCardsSliderWidget,
  [BASE_WIDGET_MAP_TYPES.MOBILE_CATEGORY_PALETTE]: MobileCategoryPalette,
  [BASE_WIDGET_MAP_TYPES.DESKTOP_CATEGORY_PALETTE]: DesktopCategoryPalette,
  [BASE_WIDGET_MAP_TYPES.TESTIMONIAL_PRODUCT_CARD_CAROUSEL]:
    TestimonialProductCardCarousel,
  [BASE_WIDGET_MAP_TYPES.IMAGE_WITH_DESCRIPTION_SLIDER]:
    ImageWithDescriptionSlider,
  [BASE_WIDGET_MAP_TYPES.DESKTOP_IMAGE_SLIDER]: MediaSlider,
  [BASE_WIDGET_MAP_TYPES.MOBILE_IMAGE_SLIDER]: MediaSlider,
  [BASE_WIDGET_MAP_TYPES.PRODUCT_LISTING]: ProductListing,
  [BASE_WIDGET_MAP_TYPES.CATEGORY_PRODUCT_CARD_CAROUSEL]:
    CategoryProductCardCarousel,
  [BASE_WIDGET_MAP_TYPES.SEARCH_INPUT_WITH_RECOMMENDATION]:
    SearchInputWithRecommendationWrapper,
  [BASE_WIDGET_MAP_TYPES.STICKY_CART_SUMMARY]: StickyCartSummary,
  [BASE_WIDGET_MAP_TYPES.DOCTOR_LISTING_CARD]: DoctorListingCard,
  [BASE_WIDGET_MAP_TYPES.DOCTOR_APPOINTMENT_CARD]: DoctorAppointmentCard,
  [BASE_WIDGET_MAP_TYPES.HYBRID_LAYOUT]: HybridLayoutWidget,
  [BASE_WIDGET_MAP_TYPES.DOCTOR_LISTING_HEADING]: DoctorListingHeading,
  [BASE_WIDGET_MAP_TYPES.DOCTOR_LISTING_CAROUSEL]: DoctorListingCarousel,
  [BASE_WIDGET_MAP_TYPES.DOCTOR_LISTING_HTML_TEXT]: DoctorListingHtmlText,
  [BASE_WIDGET_MAP_TYPES.DOCTOR_LISTING_GRID]: DoctorListingGrid,
  [BASE_WIDGET_MAP_TYPES.DOCTOR_LISTING_CRED_BANNER]: DoctorListingCredBanner,
  [BASE_WIDGET_MAP_TYPES.DESKTOP_PRODUCT_CARD_GRID]: ProductCardGrid,
  [BASE_WIDGET_MAP_TYPES.MOBILE_PRODUCT_CARD_GRID]: ProductCardGrid,
  [BASE_WIDGET_MAP_TYPES.MOBILE_CATEGORY_PRODUCT_CARD_CAROUSEL]:
    CategoryProductCardCarousel,
  [BASE_WIDGET_MAP_TYPES.DESKTOP_CATEGORY_PRODUCT_CARD_CAROUSEL]:
    CategoryProductCardCarousel,
  [BASE_WIDGET_MAP_TYPES.FEEDBACK_BANNER]: FeedbackBannerWidget,
  [BASE_WIDGET_MAP_TYPES.ORDERS_LISTING_IN_SUPPORT]:
    OrdersListingInSupportWidget,
  [BASE_WIDGET_MAP_TYPES.SUPPORT_TICKETS_LISTING]: SupportTicketsListingWidget,
  [BASE_WIDGET_MAP_TYPES.SUPPORT_MENU_ITEMS]: SupportMenuItemsWidget,
}
