import {isEmpty} from 'lodash'
import {useCallback, useMemo} from 'react'
import {pushToDataLayer} from 'src/analytics'
import {useClientSideExperimentsStore} from 'src/stores/clientSideExperimentsStore/useClientSideExperimentsStore.store'

interface state {
  experimentsList: Record<string, any> | null
}

interface actions {
  updateDataLayerAndStore: (experiments: Array<Record<string, any>>) => void
  getExperimentValue: (key: string) => boolean | string
}

const useGetClientSideExperiments = (): [state, actions] => {
  const experimentsList = useClientSideExperimentsStore(
    (state) => state.experimentsList
  )
  const updateEnableExperimentsList = useClientSideExperimentsStore(
    (state) => state.updateEnableExperimentsList
  )

  const updateDataLayerAndStore = useCallback(
    async (experiments: Array<Record<string, any>> = []) => {
      const updateDataLayer: Record<string, any> = {}
      const updateStore: Array<{key: string; value: any}> = []

      experiments?.forEach((experimentKey) => {
        const {key = '', value = false} = experimentKey || {}
        if (key) {
          updateDataLayer[key] = value
          updateStore.push({key, value})
        }
      })

      if (!isEmpty(updateDataLayer)) {
        await pushToDataLayer({
          clientSideExperiments: {
            ...(window.customDataLayer?.clientSideExperiments ?? {}),
            ...updateDataLayer,
          },
        })
      }

      updateEnableExperimentsList(updateStore)
    },
    [updateEnableExperimentsList]
  )

  const getExperimentValue = useCallback(
    (key: string) => {
      return key ? experimentsList?.[key] || false : false
    },
    [experimentsList]
  )

  const state = useMemo(() => {
    return {experimentsList}
  }, [experimentsList])

  const actions = useMemo(() => {
    return {updateDataLayerAndStore, getExperimentValue}
  }, [getExperimentValue, updateDataLayerAndStore])

  return [state, actions]
}

export default useGetClientSideExperiments
