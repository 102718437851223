import {useRouter} from 'next/router'
import {useCallback} from 'react'
import {CLIENT_ENV} from 'src/constants/clientEnv'
import {isBrandLJ} from 'src/utils/isBrandLJ'

const BRAND = CLIENT_ENV.NEXT_PUBLIC_BRAND || 'lj'

export const useRedirectToHomepage = () => {
  const router = useRouter()

  const redirectToHomepage = useCallback(() => {
    if (isBrandLJ) {
      router.push('/')
    } else {
      window.location.href = '/'
    }
  }, [router])

  return {redirectToHomepage}
}
