import {CLIENT_ENV} from 'src/constants/clientEnv'

export const API_END_POINTS = {
  GET_PRODUCTS_BY_IDS: 'product/recently-viewed', // ? using an existing endpoint and hence the no-intutiveness of API endpoint
  GET_FORM_DATA: 'growth/post-order-redirect',
  SUBMIT_FORM_DATA: 'growth/add-child-multiple',
  PINCODE_V2: 'utility/mwsc/v2/pincode',
  SET_APPOINTMENT_LANGUAGE: '/consult/appointment/language',
  GET_REWARD_METHODS_DATA: 'wallet/twid', //TWID Pay
  VERIFY_CARD_FOR_REWARD: 'wallet/twid/verify', //verify card against bins for card reward option
  CHECK_PRESCRIPTION_V2: 'consult/v2/check-prescription',
  ROOK_CONNECTION_PAGE_V2: `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}growth/get-connection-page-details`,
  ROOK_MODIFY_GOALS: `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}growth/rook-modify-patient-goals`,
  ROOK_HEALTH_PAGE: `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}growth/rook-health-page`,
  ROOK_COLLECT_GOAL_REWARD: `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}growth/goals/reward`,
  ROOK_CHOOSE_GOALS_API: `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}growth/fetch-goal-selection-details`,
  ROOK_CONFIRM_GOALS: `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}growth/set-user-health-goals`,
  AUTO_PAY_REQUEST: `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}payment/mandate/request`,
  GET_REGION_DATA: `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}utility/mwsc/v2/pincode`,
  WALLET_TRANSACTIONS: `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}wallet/transactions`,
  GET_WALLET_TRANSACTION_REWARDS: `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}wallet/reward-points`,
  VERIFY_VPA: `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}payment/vpa/verify`,
  POST_ATTRIBUTION_DATA: `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}user/data`,
  ORDER_DETAILS: `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}order/v2`,
  THANK_YOU_PAGE: `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}order/v3`,
  ORDER_LISTING: `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}order`,
  CHANGE_ADDRESS_FOR_ORDER: 'order/change-address',
  TRACK_ORDER: 'order/track-order',
  CANCEL_ORDER: 'order/v2/cancelOrder',
  CANCEL_APPOINTMENT: 'consult/cancel',
  FUTURE_APPOINTMENT_DETAILS: 'consult/future-appointment',
  SHOP_RESTOCK: `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}page/reorder`,
  USER_INFO: 'user/user-info',
  CONTACT_US: 'page/contact-us',
  GET_APP_RATING_MODAL_DATA: 'utility/display-review-modal',
  SEND_APP_DOWNLOAD_MESSAGE: 'user/get-app-token',
  ADD_CHILD_DATA: 'growth/child',
  RECENTLY_VIEWED_PRODUCTS: `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}product/recently-viewed`,
  ASK_QUESTION_PDP: `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}product/question`,
  ADD_REVIEW_PDP: `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}product/review`,
  ADD_REVIEW_VOTES: `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}product/review/vote`,
  AVAILABLE_OFFERS: `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}offers`,
  GET_REGIME: `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}regime`,
  GET_FILTERED_PRODUCTS: `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}regime/products`,
  GET_REVIEWS: (id: string) =>
    `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}product/mwsc/${id}/review`,
  GET_QUESTIONS_AND_ANSWERS: (id: string) =>
    `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}product/mwsc/${id}/questions`,
  GET_PREVIOUS_ORDERS: `${CLIENT_ENV.NEXT_PUBLIC_BACKEND}order/previous-products?theme=rcl`,
  REWIND: 'growth/rewind',
  DEFAULT_ADDRESS: 'address/set-default-address',
  USER_ADDRESS: 'address/V2',
  GET_STATES: 'address/mwsc/states',
  RESCHEDULE_APPOINTMENT: 'consult/reschedule-appointment',
  GET_SLOTS_DATA: 'consult/confirm-appointment',
  FORM_QUESTIONS: (version = '') => {
    if (version === 'v2') {
      return `consult/form/v2/fetch-questions`
    }

    return 'consult/form/fetch-questions'
  },
  FETCH_LAST_FORM_ID: `consult/form/fetch-last-form`,
  CLAIM_FORM: `consult/form/claim-form`,
  GET_APPOINTMENT_DETAILS: 'consult/get-appointment-details',
  GET_RX_ORDER_BOOKING_STATUS: 'consult/get-order-appointment-details',
  UPLOAD_IMAGES: 'consult/v2/upload-images',
  BOOK_SLOT: 'consult/book-slot',
  PAYMENT_DATA: 'page/mwsc/static/checkout-payment',
  CRED_ELIGIBILITY: ({credAppPresent = 'N'} = {}) =>
    `checkout/check-cred-eligibility?cred_app_present=${credAppPresent}`,
  TABBY_ELIGIBILITY: ({addressId = ''} = {}) =>
    `checkout/check-tabby-eligibility?addressId=${addressId}`,
  COD_ELIGIBILITY: ({CODQueryString = ''} = {}) =>
    `checkout/cod-eligibility?${CODQueryString}`,
  GET_PAYMENT_INFO: `payment/request`,
  FETCH_PAYMENT_STATUS: `payment/polling`,
  PLACE_COD_ORDER: 'checkout/gokwik-cod',
  WALLET_ELIGIBILITY: `wallet/eligibility`,
  USER_CONTENT: 'user/content',
  CART: 'cart/V2',
  ADD_PRODUCTS_TO_CART: 'cart/multi-product-atc',
  CART_APPLY_DISCOUNT: 'cart/apply-discount',
  CART_REMOVE_DISCOUNT: 'cart/remove-discount',
}
