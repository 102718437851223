import Modal from '../../../ComponentsV2/common/Modal'
import {noop} from 'lodash'
import ImageCarousel from '../../../ComponentsV2/Screens/LJ-PDP/ImageCarousel'
import {BottomSheetWrapper} from './MiniProductPageBottomSheet.styles'
import {ProductDetailCard} from 'src/Screens/Product/imports'
import Details from '../../../ComponentsV2/Screens/LJ-PDP/Description'
import HowItWorks from '../../../ComponentsV2/Screens/LJ-PDP/HowItWorks'
import Ingredients from '../../../ComponentsV2/Screens/LJ-PDP/Ingredients'
import RatingsAndReview from '../../../ComponentsV2/Screens/LJ-PDP/RatingsAndReview'
import WeGotAnswers from '../../../ComponentsV2/Screens/LJ-PDP/WeGotAnswers'
import {Button} from '../../../dls/atomic/Button'
import PDPSkeleton from 'src/components/PageLoading/ProductPageLoading/PDPSkeleton'
import {useMiniProductPageBottomSheet} from './useMiniProductPageBottomSheet'
import {IMiniProductPageBottomSheetProps} from './MiniProductPageBottomSheet.interface'
import {useInnerHeight} from 'src/hooks'
import {useMemo} from 'react'

const MiniProductPageBottomSheet: React.FC<
  IMiniProductPageBottomSheetProps
> = ({state, actions}) => {
  const {
    urlKey,
    showModal,
    isLoading,
    handleCloseModal,
    imageGallery,
    productInfo,
    isDiscontinuedProduct,
    outOfStock,
    description,
    howItWorks,
    ingredients,
    weGotAnswers,
    ratingAndReviewsData,
    cta,
  } = useMiniProductPageBottomSheet({state, actions})
  const {innerHeight} = useInnerHeight()

  const bottomSheetHeight = useMemo(
    () => (innerHeight ? innerHeight * 0.8 : null),
    [innerHeight]
  )

  const {
    showCta = false,
    label = '',
    variant = 'PRIMARY',
    size = 'LARGE',
    isSticky = true,
    onClick: handleCtaClick = noop,
  } = cta || {}

  if (!urlKey) return null

  return (
    <BottomSheetWrapper bottomSheetHeight={bottomSheetHeight}>
      <Modal
        showModal={showModal}
        onClose={handleCloseModal}
        isPadding={false}
        additionalModalClass="modal"
        additionalContentClass="modal-content"
      >
        {isLoading ? (
          <div className="product-preview-wrapper">
            <PDPSkeleton isMobile={true} />
          </div>
        ) : (
          <div className="product-preview-wrapper">
            <div className="image-carousel-wrapper">
              <ImageCarousel
                state={{
                  isMobile: true,
                  carouselImages: imageGallery,
                  showBottomThumbnail: true,
                  showImageGalleryCTA: false,
                  isNudgeVisible: false,
                  isOOS: outOfStock,
                  oosOverlayVisible: true,
                  showBannerAction: false,
                }}
              />
            </div>
            <ProductDetailCard
              data={{...productInfo, showSnapMintWidget: false}}
              isRatingClickable={false}
            />
            {!isDiscontinuedProduct && (
              <>
                <Details data={{...description, usage: {}}} />
                <HowItWorks state={howItWorks} />
                <Ingredients
                  data={{...ingredients, showAllIngredients: false}}
                />
                <RatingsAndReview
                  data={ratingAndReviewsData}
                  showActions={false}
                />
                <WeGotAnswers state={weGotAnswers} />
              </>
            )}
            {showCta && (
              <div className="cta-wrapper" data-sticky={isSticky}>
                <Button
                  label={label}
                  variant={variant}
                  onClick={handleCtaClick}
                  fullWidth={true}
                  size={size}
                />
              </div>
            )}
          </div>
        )}
      </Modal>
    </BottomSheetWrapper>
  )
}

export default MiniProductPageBottomSheet
