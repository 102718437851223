import {useUserStore} from '@mosaic-wellness/redux-action-library'
import {IUserContentData, IUserContentProps} from './useUserContent.interface'
import {fetchResource, useFetch} from '@mosaic-wellness/core-utils'
import {API_END_POINTS} from 'src/constants/apiEndPoints'
import {useCallback} from 'react'
import {IGlobalResponse} from 'src/interfaces/globalResponse.interface'
import {useQueryClient} from 'react-query'

function useUserContent<T extends keyof IUserContentData = 'NUDGE'>(
  props: IUserContentProps = {}
) {
  const {types = 'NUDGE'} = props || {}
  const {user: {isLoggedIn = false} = {}} = useUserStore()
  const {isLoading, isError, data} = useFetch<
    IGlobalResponse<IUserContentData>
  >({
    queryKey: ['user-content', types],
    url: API_END_POINTS.USER_CONTENT,
    disabled: true,
    cacheTime: Infinity,
    queryOptions: {
      retry: 0,
      staleTime: Infinity,
      queryFn: () => null,
    },
  })
  const queryClient = useQueryClient()

  const getUserContent = useCallback(
    async ({version = ''} = {}) => {
      if (!isLoggedIn) return

      await queryClient.fetchQuery<IGlobalResponse<IUserContentData>>(
        ['user-content', types],
        () =>
          fetchResource(API_END_POINTS.USER_CONTENT, {params: {types, version}})
      )
    },
    [isLoggedIn, queryClient, types]
  )

  const updateNudgeDataInStore = useCallback(
    (data: IUserContentData) => {
      queryClient.setQueryData<IGlobalResponse<IUserContentData>>(
        ['user-content', types],
        (previousData) => {
          return {
            ...previousData,
            data: {
              ...previousData?.data,
              [types]: data,
            },
          }
        }
      )
    },
    [queryClient, types]
  )

  return [
    {
      isLoading,
      isError,
      data: data?.data[types] as IUserContentData[T],
    },
    {getUserContent, updateNudgeDataInStore},
  ] as const
}

export {useUserContent}
