import {useRouter} from 'next/router'
import {useCallback} from 'react'
import {CLIENT_ENV} from 'src/constants/clientEnv'
interface productNavParmas {
  slugUrl?: string
  urlKey: string
}

const productNavPath: Record<string, string> = {
  mm: 'dp',
  'mm-co': 'dp',
  lj: 'product',
  'lj-co': 'product',
  bw: 'product',
  'bw-ae': 'product',
  'bw-co': 'product',
  'lj-ae': 'product',
  'mm-ae': 'product',
  'lj-sa': 'product',
  'rl-in': 'product',
  'rl-us': 'product',
}

const ABSOLUTE_URL_TEST_REGEX = /^(http|https):\/\//

const BRAND = CLIENT_ENV.NEXT_PUBLIC_BRAND || 'lj'

export const useProductNavigation = () => {
  const router = useRouter()

  const navigationPath = useCallback((urlKey) => {
    const productPrefix = productNavPath[BRAND]
    return `/${productPrefix}/${urlKey}`
  }, [])

  const navigateToProduct = useCallback(
    (navigationKeys: productNavParmas) => {
      const {urlKey = '', slugUrl = ''} = navigationKeys || {}
      if (!urlKey && !slugUrl) return

      if (ABSOLUTE_URL_TEST_REGEX.test(slugUrl)) {
        window.location.href = slugUrl
        return
      }

      const productPrefix = productNavPath[BRAND]
      let pathToNavigateTo = slugUrl || `/${productPrefix}/${urlKey}`

      //keeping this condition for handling wrong data in product variations
      if (slugUrl === urlKey) {
        pathToNavigateTo = `/${productPrefix}/${urlKey}`
      }

      if (!pathToNavigateTo.startsWith('/')) {
        pathToNavigateTo = `/${pathToNavigateTo}`
      }

      router.push(pathToNavigateTo)
    },
    [router]
  )
  return {navigateToProduct, navigationPath}
}
